import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    backgroundColor: 'transparent',
    height: '92px',
    width: '100%',
    transition: 'height .8s, background-color .1s linear',
    zIndex: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    gap: '30px',
    top: '0',
    [themeBreakpoints.up('md')]: {
      height: '150px',
      top: '35px',
    },
  },
  minimizedNavBar: {
    height: '92px',
    backgroundColor: 'white',
    boxShadow: '0px 10px 50px 0px rgba(66, 66, 66, 0.05)',
    '& .mainLogo': {
      opacity: '0',
    },
    '& .secondaryLogo': {
      opacity: '1',
    },
    '& .menuSvg': {
      color: COLORS.orange,
    },
    [themeBreakpoints.up('md')]: {
      height: '85px',
      '& .mainLogo': {
        height: '85px',
        opacity: '0',
      },
      '& .secondaryLogo': {
        height: '85px',
        opacity: '1',
      },
      '& .navLink': {
        color: COLORS.black,
        marginBottom: 0,
        '&:hover': {
          cursor: 'pointer',
          color: COLORS.orange,
        },
      },
    },
  },
  logoWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    height: 'auto',
  },
  mainLogo: {
    height: '65px',
    width: 'auto',
    transition: 'all .8s',
    opacity: '1',
    [themeBreakpoints.up('md')]: {
      height: '150px',
    },
  },
  secondaryLogo: {
    position: 'absolute',
    transition: 'all .8s',
    top: 0,
    height: '65px',
    width: 'auto',
    opacity: '0',
    [themeBreakpoints.up('md')]: {
      height: '150px',
    },
  },
  navButtonDesktop: {
    display: 'none',
    color: COLORS.white,
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: ['BakBak One'].join(','),
    fontWeight: 400,
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    textDecoration: 'none',
    transition: 'margin .8s',
    '&:hover': {
      cursor: 'pointer',
      color: COLORS.lime,
    },
    [themeBreakpoints.up('md')]: {
      display: 'block',
      marginBottom: '22px',
      '&.active': {
        color: COLORS.orange,
      },
    },
  },
  menuButtonMobile: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    top: '30px',
    right: '17px',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    [themeBreakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenuSvg: {
    color: 'white',
    height: '18px',
    width: '25.5px',
  },
  mobileCloseSvg: {
    color: COLORS.orange,
    height: '20.5px',
    width: '20.5px',
  },
  navMenu: {
    height: 0,
    width: '100%',
    transition: 'height .1s linear',
    position: 'absolute',
    top: '100%',
    backgroundColor: COLORS.lightLime,
    overflow: 'hidden',
  },
  navMenuOpen: {
    height: '148px',
    width: '100%',
    transition: 'height .3s linear',
  },
  navButtonMobile: {
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    paddingLeft: '20px',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: ['Poppins'].join(','),
    fontWeight: 500,
    textDecoration: 'none',
    color: COLORS.black,
    '&:hover': {
      cursor: 'pointer',
      color: COLORS.orange,
      fill: COLORS.orange,
    },
  },
  navButtonMobileIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
}));

export default useStyles;
