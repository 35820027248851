import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  toursWrapper: {
    backgroundColor: COLORS.white,
    height: '100%',
    width: '100%',
    marginTop: '117px', // margen desde el navbar al componente principal
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
      marginTop: '92px', // margen desde el navbar al componente principal
    },
  },
  toursContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    paddingTop: '0px',
    paddingBottom: '25px',
    margin: '60px 0 120px',
    [themeBreakpoints.down('md')]: {
      margin: '40px 0 50px 0',
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      paddingTop: '0',
      paddingBottom: '50px',
    },
  },
  toursHeader: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'end',
  },
  headerLinkSvg: {
    color: 'inherit',
  },
  headerLink: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    gap: '5px',
    textDecoration: 'none',
    border: '0',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: COLORS.orange,
    },
  },
  headerNavigation: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  headerNavigationButton: {
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  headerNavigationText: {
    margin: 0,
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
  },
  homeSvg: {
    width: '20px',
    height: '20px',
    color: COLORS.gray,
  },
  caretSvg: {
    width: '12px',
    height: '12px',
    color: COLORS.gray,
  },
  headerMainTitle: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 600,
    fontSize: '26px',
    lineHeight: '40px',
    margin: 0,
  },
  headerMainSubtitle: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    margin: 0,
    '& span': {
      fontWeight: 400,
    },
  },
  headerDivider: {
    margin: '22px 0 10px',
    width: '100%',
    borderBottom: `1px solid ${COLORS.lightGray}`,
    [themeBreakpoints.up('lg')]: {
      margin: '52px 0 15px',
    },
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px !important',
    [themeBreakpoints.up('lg')]: {
      marginBottom: '15px !important',
    },
  },
  filterMatchText: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 600 + '!important',
    fontSize: '12px !important',
    lineHeight: '18px !important',
    padding: '10px',
    backgroundColor: 'transparent',
    border: `1px solid ${COLORS.gray}`,
    borderRadius: '10px',
    gap: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
    [themeBreakpoints.up('lg')]: {
      gap: 0,
      borderRadius: '5px',
      '& span': {
        fontWeight: 400,
        marginLeft: '4px',
      },
    },
  },
  customSelect: {
    '& .MuiSelect-select': {
      display: 'flex',
      padding: '10px',
      border: '0',
    },
    '& fieldset': {
      border: '0',
    },
  },
  filterButtonGroup: {
    display: 'flex',
    gap: '10px',
  },
  filterSvg: {
    color: COLORS.gray,
  },
  mobileFiltersShadow: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(66,66,66,0.5)',
    zIndex: 1010,
  },
  mobileFiltersContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    left: 0,
    width: 'calc(100% - 80px)',
    height: '640px',
    borderRadius: '20px 20px 0 0',
    backgroundColor: COLORS.white,
    zIndex: 1010,
    padding: '20px 40px',
    [themeBreakpoints.up('md')]: {
      width: '500px',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      borderRadius: '20px',
      padding: '40px',
    },
  },
  mobileFiltersHead: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
    marginBottom: '15px',
  },
  mobileFilters: {
    width: '100%',
    padding: '0',
    marginBottom: '35px',
    [themeBreakpoints.up('md')]: {
      width: '420px',
    },
  },
  filterTitle: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '18px',
    margin: 0,
    marginBottom: '12px',
  },
  closeFiltersBtn: {
    height: '24px',
    width: '24px',
    position: 'absolute',
    top: '0',
    right: '0',
    padding: 0,
    backgroundColor: 'transparent',
    border: '0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeSvg: {
    color: COLORS.gray,
  },
  applyFiltersButton: {
    position: 'absolute',
    border: '0',
    bottom: '20px',
    backgroundColor: COLORS.green,
    height: '40px',
    color: COLORS.white,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(100% - 80px)',
    borderRadius: '50px',
    '&:hover': {
      cursor: 'pointer',
    },
    [themeBreakpoints.up('md')]: {
      bottom: '40px',
      width: 'calc(100% - 80px)',
    },
  },
  desktopFilters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '17px',
  },
  filterGroup: {
    position: 'relative',
    width: '200px',
    height: 'auto',
    marginBottom: '20px',
    [themeBreakpoints.up('lg')]: {
      marginBottom: '35px',
    },
  },
  filterText: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    margin: '0',
  },
  filterCheckbox: {
    marginBottom: '10px',
  },
  mobileOnly: {
    display: 'flex',
    [themeBreakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopOnly: {
    display: 'none',
    [themeBreakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  tourViewerContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    [themeBreakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  tourViewerFilters: {
    display: 'flex',
    width: '100%',
    [themeBreakpoints.up('lg')]: {
      width: 'calc(100% - 850px)',
    },
  },
  tourViewerCards: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [themeBreakpoints.up('lg')]: {
      width: '850px',
    },
  },
  customCardsGrid: {
    '& > div': {
      padding: '32px 0 0 0',
    },
    '& div:first-child': {
      paddingTop: '0',
    },
    [themeBreakpoints.only('md')]: {
      '& > div': {
        padding: '32px 0 0 32px',
      },
      '& > div:nth-child(-n+2)': {
        paddingTop: '0',
      },
      '& > div:nth-child(odd)': {
        paddingLeft: '0',
      },
    },
  },
  skeletonGrid: {
    '& > div': {
      margin: '32px 0 0 0',
    },
    '& div:first-child': {
      marginTop: '0',
    },
    [themeBreakpoints.only('md')]: {
      '& > div': {
        margin: '32px 0 0 32px',
      },
      '& > div:nth-child(-n+2)': {
        marginTop: '0',
      },
      '& > div:nth-child(odd)': {
        marginLeft: '0',
      },
    },
  },
  availableToursSkeleton: {
    width: '100px',
    height: '20px',
    transform: 'unset',
  },
  tourCardSkeleton: {
    width: '100%',
    height: '620px !important',
    transform: 'unset',
    borderRadius: '10px',
    [themeBreakpoints.up('md')]: {
      width: '100%',
    },
    [themeBreakpoints.up('lg')]: {
      width: '850px',
      height: '304px !important',
    },
  },
  noResultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    margin: '70px 0 180px 0',
    [themeBreakpoints.up('lg')]: {
      margin: '70px 0',
    },
  },
  noResultsMainText: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '40px',
    margin: '0',
  },
  noResultsSecondaryText: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0',
    maxWidth: '484px',
    textAlign: 'center',
  },
  noResultsSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  noResultsIcon: {
    color: COLORS.gray,
  },
  searchContainer: {
    display: 'flex',
    gap: '8px',
  },
  clearSearchButton: {
    color: COLORS.orange,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default styles;
