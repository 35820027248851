import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    border: '1px solid #F5F5F5',
    boxShadow: '0px 0px 5px 3px rgba(159, 159, 159, 0.10)',
    padding: '30px',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
  userWrapper: {
    display: 'flex',
    marginBottom: '14px',
    gap: 10,
  },
  usernameWrapper: {
    width: 'calc(100% - 62px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  userName: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '27px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userLocation: {
    fontSize: '12px',
    fontWeight: '400',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userImage: {
    width: '52px',
    height: '52px',
    borderRadius: '50%',
  },
  starsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  circleIcon: {
    fontSize: '15px',
    color: '#00aa6c',
  },
  dateText: {
    fontFamily: ['Poppins'].join(','),
    fontSize: '12px',
    fontWeight: '400',
    color: COLORS.gray,
  },
  titleText: {
    fontSize: '14px',
    fontWeight: '700',
    color: COLORS.gray,
    marginBottom: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  reviewText: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.gray,
    display: '-webkit-box',
    WebkitLineClamp: 5,
    lineClamp: 5,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}));

export default useStyles;
