import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {
  CloseOutlined,
  Map,
  MenuBook,
  MenuOutlined,
  PermContactCalendar,
  SupervisorAccount,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import whiteLogo from '../../assets/logo_white.svg';
import { PATHS } from '../../constants/index';
import { deepLinkTo } from '../../helpers/DeepLinking';
import breakpoints from '../../theme/breakpoints';
import SubNavbar from '../SubNavbar/subNavbar';
import useStyles from './styles';

export default function NavbarWrapper(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const themeBreakpoints = createBreakpoints(breakpoints);
  const isMediumBp = useMediaQuery(themeBreakpoints.up('md'), {
    noSsr: true,
  });
  const [onInitialPosition, setOnInitialPosition] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const fontStyle = `${classes.navButtonDesktop} navLink`;
  const location = useLocation();
  const isHomePage = useMemo(() => location.pathname === PATHS.home, [location]);
  const { t } = useTranslation();

  const navLinks = [
    {
      label: t('navbar.province'),
      url: PATHS.home,
    },
    {
      label: t('navbar.tour'),
      url: PATHS.tourCatalogue,
    },
    {
      label: t('navbar.aboutus'),
      url: PATHS.about,
    },
    {
      label: t('navbar.contact'),
      url: PATHS.contact,
    },
  ];

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title });
  }, [location]);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position === 0) {
      setOnInitialPosition(true);
    }
    if (position !== 0) {
      onInitialPosition && setOnInitialPosition(false);
    }
  };

  const isCurrentPage = (pagePath) => {
    return location.pathname === pagePath;
  };

  const deepLink = (pagePath, sectionId) => {
    if (window.location.pathname === pagePath) {
      deepLinkTo(sectionId);
    } else {
      navigate(`${pagePath}#${sectionId}`);
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      const hash = location.hash;
      if (hash) {
        const cleanId = hash.substring(1);
        deepLinkTo(cleanId);
      }
    }, 500);
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <SubNavbar isTransparent={onInitialPosition && isHomePage} />
      <div
        className={`${classes.navbarWrapper} ${!onInitialPosition || (!isMediumBp && mobileMenuOpen) || !isHomePage ? classes.minimizedNavBar : ''}`}
      >
        <button
          className={`${fontStyle}`}
          onClick={() => deepLink(navLinks[0].url, 'our-province')}
        >
          {navLinks[0].label}
        </button>
        <Link
          className={`${fontStyle} ${isCurrentPage(navLinks[1].url) && 'active'}`}
          to={navLinks[1].url}
        >
          {navLinks[1].label}
        </Link>
        <Link className={`${classes.logoWrapper} logoWrapper`} to={PATHS.home}>
          <img className={`${classes.mainLogo} mainLogo`} src={whiteLogo} />
          <img className={`${classes.secondaryLogo} secondaryLogo`} src={logo} />
        </Link>
        <Link
          className={`${fontStyle} ${isCurrentPage(navLinks[2].url) && 'active'}`}
          to={navLinks[2].url}
        >
          {navLinks[2].label}
        </Link>
        <Link
          className={`${fontStyle} ${isCurrentPage(navLinks[3].url) && 'active'}`}
          to={navLinks[3].url}
        >
          {navLinks[3].label}
        </Link>
        {!isMediumBp && (
          <>
            <button
              className={`${classes.menuButtonMobile}`}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen ? (
                <CloseOutlined sx={{ fontSize: '32px' }} className={`${classes.mobileCloseSvg}`} />
              ) : (
                <MenuOutlined
                  sx={{ fontSize: '32px' }}
                  className={`${classes.mobileMenuSvg} menuSvg`}
                />
              )}
            </button>
            <div className={`${classes.navMenu} ${mobileMenuOpen && classes.navMenuOpen}`}>
              <button
                className={classes.navButtonMobile}
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen);
                  deepLink(navLinks[0].url, 'our-province');
                }}
              >
                <Map sx={{ fontSize: '24px' }} className={`${classes.navButtonMobileIcon}`} />
                {navLinks[0].label}
              </button>
              <Link
                className={classes.navButtonMobile}
                to={navLinks[1].url}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <MenuBook sx={{ fontSize: '24px' }} className={`${classes.navButtonMobileIcon}`} />
                {navLinks[1].label}
              </Link>
              <Link
                className={classes.navButtonMobile}
                to={navLinks[2].url}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <SupervisorAccount
                  sx={{ fontSize: '24px' }}
                  className={`${classes.navButtonMobileIcon}`}
                />
                {navLinks[2].label}
              </Link>
              <Link
                className={classes.navButtonMobile}
                to={navLinks[3].url}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <PermContactCalendar
                  sx={{ fontSize: '24px' }}
                  className={`${classes.navButtonMobileIcon}`}
                />
                {navLinks[3].label}
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}
