import { useLocation } from 'react-router-dom';

export function getUrlQueryParams() {
  const parameters = new URLSearchParams(useLocation().search);
  const newParameters = new URLSearchParams();
  for (const [name, value] of parameters) {
    newParameters.append(name.toLowerCase(), value);
  }
  return newParameters;
}
