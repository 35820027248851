export const FacebookSvg = ({ classes }) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    className={classes}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='1' y='1' width='28' height='28' rx='4' stroke='currentColor' strokeWidth='2' />
    <g clipPath='url(#clip0_1035_743)'>
      <path
        d='M11.0614 18.2498H13.5826V28.629C13.5826 28.834 13.7486 29 13.9535 29H18.2283C18.4332 29 18.5992 28.834 18.5992 28.629V18.2987H21.4975C21.686 18.2987 21.8445 18.1573 21.8661 17.9701L22.3062 14.149C22.3183 14.0438 22.285 13.9386 22.2147 13.8597C22.1443 13.7808 22.0435 13.7356 21.9378 13.7356H18.5994V11.3403C18.5994 10.6183 18.9882 10.2521 19.755 10.2521C19.8643 10.2521 21.9378 10.2521 21.9378 10.2521C22.1427 10.2521 22.3088 10.086 22.3088 9.88114V6.37371C22.3088 6.16879 22.1427 6.00275 21.9378 6.00275H18.9296C18.9084 6.00171 18.8613 6 18.7918 6C18.2699 6 16.4556 6.10246 15.0225 7.42088C13.4346 8.8819 13.6554 10.6312 13.7081 10.9345V13.7355H11.0614C10.8565 13.7355 10.6904 13.9015 10.6904 14.1065V17.8788C10.6904 18.0837 10.8565 18.2498 11.0614 18.2498Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1035_743'>
        <rect width='23' height='23' fill='currentColor' transform='translate(5 6)' />
      </clipPath>
    </defs>
  </svg>
);

export const TripAdvisorSvg = ({ classes }) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    className={classes}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1035_716)'>
      <path
        d='M10.2441 17.7977C10.6118 17.7978 10.9711 17.6871 11.2768 17.4799C11.5825 17.2726 11.8208 16.9779 11.9615 16.6332C12.1022 16.2885 12.139 15.9091 12.0673 15.5431C11.9956 15.1771 11.8186 14.841 11.5586 14.5771C11.2987 14.3132 10.9675 14.1336 10.6069 14.0608C10.2464 13.988 9.87262 14.0254 9.53299 14.1682C9.19336 14.311 8.90308 14.5529 8.69887 14.8632C8.49466 15.1734 8.38569 15.5382 8.38574 15.9114C8.38581 16.4116 8.58163 16.8914 8.93013 17.2451C9.27862 17.5989 9.75127 17.7976 10.2441 17.7977Z'
        fill='currentColor'
      />
      <path
        d='M20.7219 17.7979C21.7484 17.7979 22.5806 16.9533 22.5806 15.9114C22.5806 14.8694 21.7484 14.0248 20.7219 14.0248C19.6954 14.0248 18.8633 14.8694 18.8633 15.9114C18.8633 16.9533 19.6954 17.7979 20.7219 17.7979Z'
        fill='currentColor'
      />
      <path
        d='M5.00543 15.9115C5.00543 18.8487 7.35082 21.2291 10.2442 21.2291C11.5649 21.2309 12.8373 20.7244 13.8046 19.8116L15.484 21.6665L17.1633 19.8127C18.1306 20.7247 19.4025 21.2308 20.7227 21.2288C23.6164 21.2288 25.9638 18.8479 25.9638 15.9113C25.9646 15.1714 25.8129 14.4396 25.5184 13.7627C25.2239 13.0859 24.7932 12.4791 24.254 11.9813L25.9682 10.0882H22.168C20.1986 8.72786 17.8715 8.00043 15.4894 8.00043C13.1073 8.00043 10.7802 8.72786 8.81081 10.0882H5L6.71422 11.9813C6.17512 12.4792 5.7446 13.0861 5.45032 13.763C5.15603 14.4398 5.00449 15.1717 5.00543 15.9115ZM24.2674 15.9115C24.2674 16.6233 24.0595 17.3191 23.6699 17.9109C23.2803 18.5027 22.7266 18.964 22.0788 19.2364C21.4309 19.5088 20.718 19.58 20.0303 19.4412C19.3425 19.3023 18.7108 18.9596 18.2149 18.4563C17.7191 17.953 17.3814 17.3117 17.2446 16.6136C17.1078 15.9155 17.178 15.1919 17.4464 14.5344C17.7147 13.8768 18.1692 13.3147 18.7522 12.9193C19.3353 12.5238 20.0207 12.3128 20.722 12.3128C21.6623 12.3128 22.5641 12.6919 23.229 13.3668C23.8939 14.0417 24.2674 14.9571 24.2674 15.9115ZM15.4832 9.74016C16.8345 9.73845 18.1726 10.01 19.4193 10.539C17.1817 11.408 15.485 13.4399 15.485 15.8077C15.485 13.4396 13.7878 11.4075 11.5499 10.5387C12.7958 10.0101 14.1329 9.73869 15.4832 9.74016ZM10.2442 12.3128C10.9454 12.3128 11.6309 12.5238 12.2139 12.9193C12.797 13.3147 13.2514 13.8768 13.5198 14.5344C13.7881 15.1919 13.8583 15.9155 13.7215 16.6136C13.5847 17.3117 13.2471 17.953 12.7512 18.4563C12.2554 18.9596 11.6236 19.3023 10.9359 19.4412C10.2481 19.58 9.53524 19.5088 8.88739 19.2364C8.23954 18.964 7.68582 18.5027 7.29624 17.9109C6.90665 17.3191 6.69872 16.6233 6.69872 15.9115C6.69872 14.9571 7.07226 14.0417 7.73716 13.3668C8.40206 12.6919 9.30387 12.3128 10.2442 12.3128Z'
        fill='currentColor'
      />
    </g>
    <rect x='1' y='1' width='28' height='28' rx='4' stroke='currentColor' strokeWidth='2' />
    <defs>
      <clipPath id='clip0_1035_716'>
        <rect width='21' height='14' fill='currentColor' transform='translate(5 8)' />
      </clipPath>
    </defs>
  </svg>
);

export const VisaSvg = ({ classes }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='24' viewBox='0 0 35 24' fill='none'>
    <rect x='0.5' y='0.5' width='34' height='23' rx='5.5' fill='white' stroke='#D9D9D9' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.6253 16.2582H8.50494L6.91495 10.1924C6.83949 9.91334 6.67925 9.66666 6.44354 9.5504C5.85531 9.25823 5.20712 9.0257 4.5 8.90843V8.67489H7.91567C8.38708 8.67489 8.74064 9.0257 8.79957 9.43313L9.62454 13.8086L11.7438 8.67489H13.8052L10.6253 16.2582ZM14.9838 16.2582H12.9813L14.6302 8.67489H16.6327L14.9838 16.2582ZM19.2234 10.7757C19.2823 10.3673 19.6358 10.1337 20.0483 10.1337C20.6965 10.0751 21.4026 10.1924 21.9919 10.4835L22.3454 8.85081C21.7562 8.61727 21.108 8.5 20.5197 8.5C18.5762 8.5 17.162 9.55041 17.162 11.0082C17.162 12.1173 18.1637 12.6996 18.8708 13.0504C19.6358 13.4002 19.9305 13.6338 19.8716 13.9835C19.8716 14.5082 19.2823 14.7418 18.6941 14.7418C17.9869 14.7418 17.2798 14.5669 16.6327 14.2747L16.2791 15.9085C16.9862 16.1996 17.7512 16.3169 18.4584 16.3169C20.6376 16.3745 21.9919 15.3251 21.9919 13.75C21.9919 11.7665 19.2234 11.6502 19.2234 10.7757ZM29 16.2582L27.41 8.67489H25.7022C25.3486 8.67489 24.9951 8.90843 24.8772 9.25823L21.933 16.2582H23.9943L24.4058 15.1502H26.9386L27.1743 16.2582H29ZM25.9968 10.7171L26.585 13.5751H24.9361L25.9968 10.7171Z'
      fill='#172B85'
    />
  </svg>
);

export const MasterCardSvg = ({ classes }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='34' height='24' viewBox='0 0 34 24' fill='none'>
    <rect x='0.5' y='0.5' width='33' height='23' rx='5.5' fill='white' stroke='#D9D9D9' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.243 17.1569C16.0855 18.1852 14.584 18.806 12.9433 18.806C9.28227 18.806 6.31445 15.7154 6.31445 11.903C6.31445 8.09057 9.28227 5 12.9433 5C14.584 5 16.0855 5.62075 17.243 6.64903C18.4006 5.62075 19.9021 5 21.5428 5C25.2038 5 28.1716 8.09057 28.1716 11.903C28.1716 15.7154 25.2038 18.806 21.5428 18.806C19.9021 18.806 18.4005 18.1852 17.243 17.1569Z'
      fill='#ED0006'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2432 17.1569C18.6684 15.8908 19.5722 14.0068 19.5722 11.903C19.5722 9.79917 18.6684 7.91516 17.2432 6.64903C18.4007 5.62075 19.9022 5 21.5429 5C25.2039 5 28.1717 8.09057 28.1717 11.903C28.1717 15.7154 25.2039 18.806 21.5429 18.806C19.9022 18.806 18.4007 18.1852 17.2432 17.1569Z'
      fill='#F9A000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2431 17.1569C18.6683 15.8908 19.5721 14.0068 19.5721 11.903C19.5721 9.79917 18.6683 7.91518 17.2431 6.64905C15.8178 7.91518 14.9141 9.79917 14.9141 11.903C14.9141 14.0068 15.8178 15.8908 17.2431 17.1569Z'
      fill='#FF5E00'
    />
  </svg>
);

export const AmexSvg = ({ classes }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='24' viewBox='0 0 35 24' fill='none'>
    <rect x='0.5' y='0.5' width='34' height='23' rx='5.5' fill='#1F72CD' stroke='#D9D9D9' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.27467 8.5L3 15.7467H6.92024L7.40624 14.5913H8.51712L9.00311 15.7467H13.3182V14.8649L13.7027 15.7467H15.9348L16.3193 14.8462V15.7467H25.2935L26.3847 14.6213L27.4065 15.7467L32.0158 15.7561L28.7308 12.1436L32.0158 8.5H27.478L26.4158 9.60463L25.4261 8.5H15.6634L14.8251 10.3704L13.9671 8.5H10.055V9.35186L9.61981 8.5H6.27467ZM19.6758 9.52905H24.8292L26.4054 11.2316L28.0324 9.52905H29.6086L27.2137 12.1426L29.6086 14.726H27.9609L26.3847 13.0036L24.7494 14.726H19.6758V9.52905ZM20.9484 11.555V10.6057V10.6048H24.164L25.5671 12.1229L24.1018 13.6493H20.9484V12.613H23.7598V11.555H20.9484ZM7.03322 9.52905H8.94413L11.1162 14.4431V9.52905H13.2095L14.8872 13.0524L16.4334 9.52905H18.5163V14.7291H17.2489L17.2386 10.6544L15.3908 14.7291H14.2571L12.3991 10.6544V14.7291H9.79178L9.29749 13.5633H6.62701L6.13374 14.728H4.73679L7.03322 9.52905ZM7.08299 12.4856L7.96281 10.4089L8.8416 12.4856H7.08299Z'
      fill='white'
    />
  </svg>
);
