import { gql } from '@apollo/client';

export const ADD_BOOKING = gql`
  mutation Mutation($data: BookingInput!) {
    createBooking(data: $data) {
      data {
        id
        attributes {
          code
          booking_date
          booking_status {
            data {
              id
            }
          }
          cruise_id {
            data {
              id
            }
          }
          payment_type {
            data {
              id
            }
          }
          quantity
          tour {
            data {
              id
            }
          }
          sub_total
          service_fee
          total
          user_email
          user_first_name
          user_last_name
          user_phone
        }
      }
    }
  }
`;

export const GET_PAYMENT_LINK = gql`
  query PaymentLinks($filters: PaymentLinkFiltersInput) {
    paymentLinks(filters: $filters) {
      data {
        attributes {
          url
        }
      }
    }
  }
`;
