import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  footerBg: {
    width: '100%',
    backgroundColor: COLORS.green,
  },
  footerWrapper: {
    position: 'relative',
    backgroundColor: COLORS.green,
    padding: '176px 0 50px 0',
    width: '100%',
    height: 'auto',
    [themeBreakpoints.up('md')]: {
      width: 'calc(100% - 140px)',
      display: 'flex',
      padding: '176px 70px 214px',
      justifyContent: 'space-between',
    },
    [themeBreakpoints.up('lg')]: {
      padding: '100px 0 175px',
      width: '100%',
      maxWidth: DIMENSIONS.MAIN_WRAPPER,
      margin: 'auto',
    },
  },
  slothWrapper: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  slothImage: {
    width: '112px',
    height: '123px',
    [themeBreakpoints.up('md')]: {
      width: '156px',
      height: '172px',
    },
  },
  logoWrapper: {
    margin: '10px auto 0',
    width: '250px',
    [themeBreakpoints.up('md')]: {
      margin: '0',
      position: 'absolute',
      bottom: '42px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [themeBreakpoints.up('lg')]: {
      width: '300px',
    },
  },
  logoImage: {
    width: '250px',
    height: 'auto',
    [themeBreakpoints.up('lg')]: {
      width: '300px',
    },
  },
  logoLinkWrapper: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    [themeBreakpoints.up('md')]: {
      maarginTop: '36px',
    },
  },
  logoLink: {
    width: '30px',
    height: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logoLinkIcon: {
    width: '30px',
    height: '30px',
    color: COLORS.white,
    '&:hover': {
      color: COLORS.lime,
    },
  },
  footerSection: {
    width: 'calc(100% - 40px)',
    margin: '0 20px 40px',
    height: 'auto',
    [themeBreakpoints.up('sm')]: {
      width: '300px',
      margin: '0 0 40px 85px',
    },
    [themeBreakpoints.up('md')]: {
      margin: '0 0 20px',
    },
  },
  footerTitle: {
    fontFamily: ['Poppins'].join(','),
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '16px',
    margin: '0 0 12px',
    color: COLORS.white,
    [themeBreakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '27px',
      margin: '0 0 15px',
    },
  },
  bulletList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  bulletWrapper: {
    display: 'flex',
    gap: '10px',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      '& p': {
        textDecoration: 'underline',
        color: COLORS.lime,
      },
      '& svg': {
        fill: COLORS.lime,
      },
    },
  },
  bulletIcon: {
    width: '20px',
    height: '20px',
    color: COLORS.white,
  },
  bulletText: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    width: '100%',
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: '16px',
    color: COLORS.white,
    margin: 0,
  },
  bulletLink: {
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: '16px',
    color: COLORS.white,
    textDecoration: 'none',
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: COLORS.lime,
    },
  },
  underFootWrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLORS.lightLime,
  },
  underFoot: {
    width: '100%',
    backgroundColor: COLORS.lightLime,
    padding: '16px 0px',
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    [themeBreakpoints.up('md')]: {
      gap: '20px',
      justifyContent: 'space-between',
      padding: '10px 10px',
    },
    [themeBreakpoints.up('lg')]: {
      gap: 0,
      flexDirection: 'row',
      padding: '10px 10px',
      maxWidth: DIMENSIONS.MAIN_WRAPPER,
    },
  },
  underFootText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: ['Poppins'].join(','),
    fontWeight: 300,
    lineHeight: '18px',
    fontSize: '12px',
    margin: 0,
    color: COLORS.gray,
    textAlign: 'center',
    [themeBreakpoints.up('md')]: {
      width: '100%',
      justifyContent: 'center',
    },
    [themeBreakpoints.up('lg')]: {
      width: 'calc(100% / 3)',
      '&:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  legalLinksWrapper: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'center',
    [themeBreakpoints.up('md')]: {
      width: '100%',
      gap: '10px',
    },
    [themeBreakpoints.up('lg')]: {
      gap: '4px',
      width: 'calc(100% / 3)',
      justifyContent: 'flex-end',
    },
  },
  legalLinkText: {
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    lineHeight: '18px',
    fontSize: '12px',
    margin: 0,
    color: COLORS.gray,
  },
  legalLink: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  flagIcon: {
    width: 'auto',
    height: '18px',
    marginLeft: '3px',
  },
}));

export default useStyles;
