import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PATHS } from '../../constants';
import { GET_POLICIES_FILES } from '../../queries/clientParams';

const DocsLoader = () => {
  const { data: policiesData, error } = useQuery(GET_POLICIES_FILES);
  const navigate = useNavigate();
  const { doc } = useParams();

  useEffect(() => {
    if (policiesData && policiesData.clientParam && policiesData.clientParam.data) {
      const {
        terms_of_service: { data: termsOfServiceData },
        reimbursements: { data: reimbursementsData },
        privacy_policy: { data: privacyPolicyData },
      } = policiesData.clientParam.data.attributes;

      if (doc === 'terms') {
        window.location.href = termsOfServiceData?.attributes?.url;
      } else if (doc === 'privacy') {
        window.location.href = privacyPolicyData?.attributes?.url;
      } else if (doc === 'refunds') {
        window.location.href = reimbursementsData?.attributes?.url;
      } else {
        navigate(PATHS.home);
      }
    }
  }, [policiesData]);

  useEffect(() => {
    if (error) {
      navigate(PATHS.notFound);
    }
  }, [error]);

  return <></>;
};
export default DocsLoader;
