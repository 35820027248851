import { gql } from '@apollo/client';

export const EXPERIENCIES_QUERY = gql`
  query Query($filters: ReviewFiltersInput) {
    reviews(filters: $filters) {
      data {
        id
        attributes {
          review_date
          text
          title
          username
          user_avatar
          rating
          url
        }
      }
    }
  }
`;
