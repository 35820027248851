import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';

const styles = makeStyles((theme) => ({
  currencySliderContainer: {
    position: 'relative',
    margin: '0 10px',
    color: COLORS.green,
    paddingTop: '20px',
  },
  currencySlider: {
    color: COLORS.green,
    '& .MuiSlider-rail': {
      backgroundColor: COLORS.black,
      opacity: 1,
      height: '2px',
    },
  },
  filterText: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    margin: '0',
  },
  currencyText: {
    position: 'absolute',
    top: 0,
    '&.right': {
      right: -10,
    },
    '&.left': {
      left: -10,
    },
  },
}));

export default styles;
