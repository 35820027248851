import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  contactWrapper: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    width: '100%',
    height: '100%',
    display: 'flex',
    margin: '200px 0 150px 0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      margin: '200px 0 150px 0',
    },
    [themeBreakpoints.down('sm')]: {
      margin: '150px 0 100px 0',
    },
  },
  contactContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  headerForm: {
    paddingBottom: '30px',
  },
  contactFormHeaderText: {
    width: '75%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  alertError: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  formTitle: {
    fontSize: '21px',
    fontWeight: 600,
    color: COLORS.gray,
    marginBottom: '15px',
  },
  titleInput: {
    fontWeight: 600,
    color: COLORS.gray,
    marginBottom: '5px',
  },
  inputTextBox: {
    marginBottom: '15px',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'end',
    marginTop: '30px',
    marginBottom: '15px',
  },
  termsText: {
    color: COLORS.gray,
    textAlign: 'end',
    paddingBottom: '10px',
    whiteSpace: 'pre-wrap',
    [themeBreakpoints.down('md')]: {
      whiteSpace: 'normal',
    },
  },
  contactInfomationBox: {
    position: 'relative',
    borderRadius: '10px',
    padding: '45px',
    boxShadow: '0px 0px 20px 5px rgba(159, 159, 159, 0.2)',
    boxSizing: 'border-box',
    [themeBreakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  butterflyImage: {
    position: 'absolute',
    width: '100%',
    transform: 'translate(-50%, -50%) scale(0.3) rotate(-15deg)',
    top: 5,
    right: 0,
    [themeBreakpoints.down('md')]: {
      transform: 'translate(-48%, -50%) scale(0.2) rotate(-15deg)',
    },
    [themeBreakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleBox: {
    paddingBottom: '8px',
  },
  infoBoxSpace: {
    paddingTop: '15px',
  },
  titleInfoBoxSpace: {
    fontWeight: '600',
    color: COLORS.gray,
  },
  mapStyling: {
    marginTop: '10px',
  },
}));

export default styles;
