import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    textDecoration: 'none',
  },
  cardWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '425px',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    [themeBreakpoints.down('md')]: {
      justifyContent: 'center',
      height: '425px',
      '&:hover': {
        transform: 'scale(1)',
      },
    },
  },
  cardSubWrapper: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  tourImage: {
    width: '100%',
    height: '345px',
    objectFit: 'cover',
    objectPosition: 'center center',
    borderRadius: '10px',
  },
  titleTour: {
    fontWeight: 600,
    lineHeight: '20px',
    marginBottom: '5px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  priceTour: {
    fontSize: '14px',
    fontWeight: 400,
  },
}));

export default useStyles;
