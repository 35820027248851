import axios from 'axios';

export const getPricesInfo = async (tourId, quantity, paymentType) => {
  const parsePaymentType = paymentType === 'card' ? '1' : '2';
  const response = await axios.get(
    `${process.env.REACT_APP_STRAPI_API}/calculate-booking?paymentType=${parsePaymentType}&tourID=${String(tourId)}&quantity=${String(quantity)}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`,
      },
    },
  );
  return response.data;
};
