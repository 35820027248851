import { useQuery } from '@apollo/client';
import { EmailOutlined, LocalPhoneOutlined, PlaceOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import crFlag from '../../assets/cr-flag.png';
import whiteLogo from '../../assets/logo_white.svg';
import footerSloth from '../../assets/sloth_footer.png';
import { FacebookSvg, TripAdvisorSvg } from '../../assets/SvgLibrary';
import { PATHS } from '../../constants';
import { GET_CLIENT_INFORMATION_DATA } from '../../queries/clientParams';
import { FEATURED_TOUR_QUERY } from '../../queries/tours';
import useStyles from './styles';

export default function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [contactLinks, setContactLinks] = useState([]);

  const {
    data: tourLinks,
    loading,
    error,
  } = useQuery(FEATURED_TOUR_QUERY, {
    variables: {
      page: 1,
    },
  });

  const {
    data: contactInformationObject,
    loading: loadingInformation,
    error: errorInformation,
  } = useQuery(GET_CLIENT_INFORMATION_DATA, {
    variables: {
      page: 1,
    },
  });

  useEffect(() => {
    if (contactInformationObject) {
      const { latitude, longitude } =
        contactInformationObject.clientParam.data.attributes.location_address;
      setContactLinks([
        {
          label: 'Puerto Limon, Costa Rica',
          url: `https://www.google.com/maps/place/Lim%C3%B3n/@${latitude},${longitude},14z/data=!4m6!3m5!1s0x8fa705367150d903:0x24d864fd050c815f!8m2!3d9.9913106!4d-83.0415078!16s%2Fm%2F0130ysp0?entry=ttu`,
          svg: <PlaceOutlined sx={{ fontSize: '20px' }} className={`${classes.bulletIcon}`} />,
        },
        {
          label: contactInformationObject.clientParam.data.attributes.phone,
          url: `tel:${contactInformationObject.clientParam.data.attributes.phone}`,
          svg: <LocalPhoneOutlined sx={{ fontSize: '20px' }} className={`${classes.bulletIcon}`} />,
        },
        {
          label: contactInformationObject.clientParam.data.attributes.email,
          url: `mailto:${contactInformationObject.clientParam.data.attributes.email}`,
          svg: <EmailOutlined sx={{ fontSize: '20px' }} className={`${classes.bulletIcon}`} />,
        },
      ]);
    }
  }, [contactInformationObject]);

  const tripadvisorURL =
    'https://www.tripadvisor.com/Attraction_Review-g309264-d8842878-Reviews-Caribbean_Adventures_Costa_Rica-Puerto_Limon_Province_of_Limon.html';
  const facebookUrl = 'https://www.facebook.com/CaribbeanAdventuresCostaRica';

  return (
    <div className={classes.footerBg}>
      <div className={classes.footerWrapper}>
        <div className={classes.slothWrapper}>
          <img className={`${classes.slothImage}`} src={footerSloth} alt='footer-sloth' />
        </div>
        <div className={classes.footerSection}>
          <h2 className={classes.footerTitle}>Tours</h2>
          <div className={classes.bulletList}>
            {!tourLinks &&
              loading &&
              !error &&
              [1, 2, 3].map((index) => {
                return (
                  <Skeleton
                    key={index}
                    variant='rectangular'
                    height={'425px'}
                    sx={{
                      borderRadius: '10px',
                      width: { xs: '100%', sm: '100%', md: 350, lg: 350 },
                    }}
                  />
                );
              })}
            {tourLinks &&
              !error &&
              !loading &&
              tourLinks.tours.data.map((tour, index) => {
                return (
                  <Link
                    key={`footer-tour-${index}`}
                    className={classes.bulletLink}
                    to={`${PATHS.tour}/${tour.id}`}
                  >
                    {tour.attributes.name}
                  </Link>
                );
              })}
          </div>
        </div>
        <div className={classes.footerSection}>
          <h2 className={classes.footerTitle}>Contact Us</h2>
          <div className={classes.bulletList}>
            {!errorInformation &&
              !loadingInformation &&
              contactLinks.map((link) => {
                return (
                  <a
                    key={`footer-contact-${link.label}`}
                    href={link.url}
                    target='_blank'
                    className={classes.bulletWrapper}
                    rel='noreferrer'
                  >
                    {link.svg}
                    <p className={classes.bulletText}>{link.label}</p>
                  </a>
                );
              })}
          </div>
        </div>
        <div className={classes.logoWrapper}>
          <a href={PATHS.home}>
            <img className={`${classes.logoImage}`} src={whiteLogo} alt='white-logo' />
          </a>
          <div className={classes.logoLinkWrapper}>
            <a
              href={facebookUrl}
              className={classes.logoLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <FacebookSvg classes={classes.logoLinkIcon} />
            </a>
            <a href={tripadvisorURL} className={classes.logoLink} target='_blank' rel='noreferrer'>
              <TripAdvisorSvg classes={classes.logoLinkIcon} />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.underFootWrapper}>
        <div className={classes.underFoot}>
          <p className={`${classes.underFootText}`}>
            {t('footer.made')}
            <img className={classes.flagIcon} src={crFlag} alt='cr-flag' />
          </p>
          <p className={`${classes.underFootText}`}>{t('footer.copyright')}</p>
          <div className={`${classes.legalLinksWrapper}`}>
            <Link
              key={`footer-docs-terms`}
              className={`${classes.legalLinkText} ${classes.legalLink}`}
              to={`${PATHS.docs}/privacy`}
            >
              {t('footer.privacy')}
            </Link>
            <span className={classes.legalLinkText}>|</span>
            <Link
              key={`footer-docs-terms`}
              className={`${classes.legalLinkText} ${classes.legalLink}`}
              to={`${PATHS.docs}/terms`}
            >
              {t('footer.terms')}
            </Link>
            <span className={classes.legalLinkText}>|</span>
            <Link
              key={`footer-docs-terms`}
              className={`${classes.legalLinkText} ${classes.legalLink}`}
              to={`${PATHS.docs}/refunds`}
            >
              {t('footer.refunds')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
