import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  pageNotFoundWrapper: {
    backgroundColor: COLORS.white,
    width: '100%',
    margin: '200px 0 150px 0',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      margin: '150px 0 100px 0',
    },
  },
  pageNotFoundContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  pageNotFoundContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  slothImage: {
    width: '400px',
    marginBottom: '30px',
    [themeBreakpoints.down('sm')]: {
      width: '300px',
    },
  },
  firstParragraph: {
    width: '80%',
    textAlign: 'center',
    marginBottom: '25px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondParragraph: {
    width: '44%',
    textAlign: 'center',
    marginBottom: '20px',
    [themeBreakpoints.down('md')]: {
      width: '70%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default styles;
