import { useQuery } from '@apollo/client';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import GrassIcon from '@mui/icons-material/Grass';
import GroupsIcon from '@mui/icons-material/Groups';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import CustomPrimaryButton from '../../components/CustomButton';
import ExperienceSmallCard from '../../components/ExperienceSmallCard/experienceSmallCard';
import Metatags from '../../components/MetaTags/metatags';
import TourSmallCard from '../../components/TourSmallCard/tourSmallCard';
import Waves from '../../components/Waves/waves';
import { COLORS, PATHS, URL_IMAGES } from '../../constants';
import { formatDate } from '../../helpers/dates';
import { formatReview } from '../../helpers/interfaces/reviewsInterface';
import { formatFeaturedTours } from '../../helpers/interfaces/tourInterface';
import { EXPERIENCIES_QUERY } from '../../queries/experiencies';
import { FEATURED_TOUR_QUERY } from '../../queries/tours';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const HomePage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const Header = () => {
    const [searchInput, setSearchInput] = useState('');

    const handleInputChange = (event) => {
      setSearchInput(event.target.value);
    };

    const searchCatalogue = () => {
      if (searchInput.trim() !== '') {
        navigate(`${PATHS.tourCatalogue}?search=${searchInput}`);
      }
    };

    return (
      <div className={classes.headerWrapper}>
        <div className={classes.headerContentWrapper}>
          <div className={classes.searchBoxContent}>
            <h1 className={clsx(fontClasses.h1, classes.headerText)}>{t('homePage.headerText')}</h1>
            <div className={classes.searchBox}>
              <SearchIcon
                style={{ fontSize: 24, color: COLORS.black, cursor: 'pointer' }}
                onClick={searchCatalogue}
              />
              <InputBase
                placeholder={t('homePage.searchBox')}
                inputProps={{ 'aria-label': 'search' }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchCatalogue(e);
                  }
                }}
                value={searchInput}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FeaturedTours = () => {
    const skeletonsList = [1, 2, 3];
    const [featuredToursList, setFeaturedToursList] = useState();
    const { data: featToursData } = useQuery(FEATURED_TOUR_QUERY, {
      variables: {
        page: 1,
      },
    });

    useEffect(() => {
      if (featToursData) {
        setFeaturedToursList(formatFeaturedTours(featToursData));
      }
    }, [featToursData]);

    let sliderRef = useRef(null);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const next = () => {
      sliderRef.slickNext();
    };
    const previous = () => {
      sliderRef.slickPrev();
    };

    const { t } = useTranslation();

    return (
      <div className={classes.toursWrapper}>
        <div className={classes.toursContentWrapper}>
          <div>
            <h2 className={clsx(fontClasses.h2, classes.titles)}>{t('homePage.toursTitle')}</h2>
            <p className={clsx(fontClasses.p, classes.toursText)}>
              {t('homePage.toursText')}{' '}
              <Link className={fontClasses.linkText} to={PATHS.tourCatalogue}>
                {t('homePage.toursLink')}
              </Link>
            </p>
            <div className={classes.toursBoxSlider}>
              <div className={classes.toursBoxButtonSlider}>
                <IconButton aria-label='previus' onClick={previous}>
                  <ArrowBackIosNewIcon sx={{ fontSize: '20px', color: COLORS.black }} />
                </IconButton>
                <IconButton aria-label='next' onClick={next}>
                  <ArrowForwardIosIcon sx={{ fontSize: '20px', color: COLORS.black }} />
                </IconButton>
              </div>

              <Slider
                {...settings}
                ref={(slider) => {
                  sliderRef = slider;
                }}
                className={classes.slider}
              >
                {!featuredToursList &&
                  skeletonsList.map((index) => {
                    return (
                      <Skeleton
                        key={index}
                        variant='rectangular'
                        height={'425px'}
                        sx={{
                          borderRadius: '10px',
                          width: { xs: '100%', sm: '100%', md: 350, lg: 350 },
                        }}
                      />
                    );
                  })}
                {featuredToursList &&
                  featuredToursList.map((tour, index) => {
                    return (
                      <TourSmallCard
                        key={index}
                        name={tour.name}
                        img={tour.img}
                        to={`${PATHS.tour}/${tour.id}`}
                        price={tour.price}
                        alt={tour.alt}
                      />
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Team = () => {
    return (
      <div className={classes.teamWrapper}>
        <div className={classes.teamContentWrapper}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            rowSpacing={{ xs: 4, md: 4 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className={classes.imageBox}>
                <img
                  src={URL_IMAGES.URL_BUTTERFLY}
                  alt='Tour image'
                  className={classes.butterflyImage}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className={classes.infomationTeam}>
                <h2 className={clsx(fontClasses.h2, classes.titles)}>
                  {t('homePage.infomationTeamTitle')}
                </h2>
                <p className={clsx(fontClasses.p, classes.teamText)}>
                  {t('homePage.infomationTeamText')}
                </p>
                <CustomPrimaryButton
                  label={t('homePage.infomationTeamButton')}
                  url={PATHS.about}
                  icon={<ArrowForwardIcon />}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const OurProvince = () => {
    return (
      <div id={'our-province'} className={classes.provinceMainWrapper}>
        <img
          src={URL_IMAGES.URL_PLANTS}
          className={clsx(classes.provinceImage, classes.provinceImageBackward)}
        />
        <div className={classes.provinceContentWrapper}>
          <div className={classes.provinceInfo}>
            <h2 className={fontClasses.h2}>{t('homePage.provinceName')}</h2>
            <p className={clsx(fontClasses.p, classes.provinceParagraph)}>
              {t('homePage.provinceInfo')}
            </p>
          </div>
          <Grid container spacing={5} justifyContent='center'>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div className={classes.provinceInfoIcon}>
                <GroupsIcon
                  className={classes.provinceIcon}
                  sx={{ fontSize: '50px', marginBottom: '20px' }}
                />
                <h5 className={clsx(fontClasses.h5, classes.provinceTitle)}>
                  {t('homePage.provinceFirstTitleInfo')}
                </h5>
                <p className={clsx(fontClasses.p, classes.paragraph)}>
                  {t('homePage.provinceFirstTitleInfoText')}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div className={classes.provinceInfoIcon}>
                <GrassIcon
                  className={classes.provinceIcon}
                  sx={{ fontSize: '50px', marginBottom: '20px' }}
                />
                <h5 className={clsx(fontClasses.h5, classes.provinceTitle)}>
                  {t('homePage.provinceSecondInfo')}
                </h5>
                <p className={clsx(fontClasses.p, classes.paragraph)}>
                  {t('homePage.provinceSecondInfoText')}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div className={classes.provinceInfoIcon}>
                <BrightnessHighIcon
                  className={classes.provinceIcon}
                  sx={{ fontSize: '50px', marginBottom: '20px' }}
                />
                <h5 className={clsx(fontClasses.h5, classes.provinceTitle)}>
                  {t('homePage.provinceThirdInfo')}
                </h5>
                <p className={clsx(fontClasses.p, classes.paragraph)}>
                  {t('homePage.provinceThirdInfoText')}
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
        <img src={URL_IMAGES.URL_PLANTS} className={classes.provinceImage} />
      </div>
    );
  };

  const Experiences = () => {
    const skeletonsList = [1, 2, 3];
    const [reviewsList, setReviewsList] = useState();

    const { data: experienciesList } = useQuery(EXPERIENCIES_QUERY, {
      variables: {
        filters: {
          rating: {
            gte: 3,
          },
        },
      },
    });

    useEffect(() => {
      if (experienciesList) {
        setReviewsList(formatReview(experienciesList));
      }
    }, [experienciesList]);

    let sliderRef = useRef(null);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const next = () => {
      sliderRef.slickNext();
    };
    const previous = () => {
      sliderRef.slickPrev();
    };

    return (
      <div className={classes.experienceWrapper}>
        <div className={classes.experienceContentWrapper}>
          <Grid container direction='column' rowSpacing={{ xs: 4, md: 4 }}>
            <h2 className={clsx(fontClasses.h2, classes.titles)}>
              {t('homePage.experienciesTitle')}
            </h2>
            <p className={clsx(fontClasses.p, classes.experiencesText)}>
              {t('homePage.experienciesText')}
            </p>
            <div className={classes.tripAdvisorWrapper}>
              <CustomPrimaryButton
                label={t('homePage.experienciesButton')}
                url={PATHS.writeReview}
                icon={<ArrowForwardIcon />}
                tertiary
              />
              <a
                href='https://www.tripadvisor.com/'
                target='blank'
                style={{ textDecoration: 'none' }}
              >
                <div className={classes.tripAdvisorElement}>
                  <p className={classes.tripAdvisorDisclaimer}>Powered by</p>
                  <img
                    src={URL_IMAGES.URL_TRIPADVISOR}
                    alt='Tripadvisor Logo'
                    className={classes.tripAdvisorImage}
                  />
                </div>
              </a>
            </div>
            <div className={classes.experienciesSlider}>
              <div className={classes.experienciesSliderWrapper}>
                <div className={classes.toursBoxButtonSlider} style={{ marginBottom: '0px' }}>
                  <IconButton aria-label='previus' onClick={previous}>
                    <ArrowBackIosNewIcon sx={{ fontSize: '20px', color: COLORS.black }} />
                  </IconButton>
                  <IconButton aria-label='next' onClick={next}>
                    <ArrowForwardIosIcon sx={{ fontSize: '20px', color: COLORS.black }} />
                  </IconButton>
                </div>
                <Slider
                  {...settings}
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  className={classes.experienceSlider}
                >
                  {!reviewsList &&
                    skeletonsList.map((index) => {
                      return (
                        <Skeleton
                          key={index}
                          variant='rectangular'
                          height={'288px'}
                          sx={{
                            borderRadius: '10px',
                            width: { xs: '100%', sm: '100%', md: 350, lg: 350 },
                          }}
                        />
                      );
                    })}
                  {reviewsList &&
                    reviewsList.length > 0 &&
                    reviewsList.map((experience, index) => {
                      return (
                        <ExperienceSmallCard
                          key={index}
                          username={experience.username}
                          img={experience.user_avatar}
                          stars={experience.rating}
                          date={formatDate(experience.review_date)}
                          title={experience.title}
                          content={experience.text}
                          url={experience.url}
                        />
                      );
                    })}
                </Slider>
                {reviewsList && reviewsList.length === 0 && (
                  <div className={classes.noResultsContainer}>
                    <RateReviewOutlinedIcon
                      sx={{ fontSize: '50px' }}
                      className={classes.noResultsIcon}
                    />
                    <div className={classes.noResultsSubContainer}>
                      <p className={fontClasses.h4}>{t('homePage.expereiencesNotFoundTitle')}</p>
                      <p className={fontClasses.p}>{t('homePage.expereiencesNotFoundText')}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <>
      <Metatags
        title='Caribbean Adventures | Home'
        description='Explore our exclusive tours to live unique experiences.'
      />
      <Header />
      <Waves />
      <FeaturedTours />
      <OurProvince />
      <Team />
      <Experiences />
    </>
  );
};

export default HomePage;
