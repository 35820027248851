import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';

const styles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterText: {
    color: COLORS.gray,
    fontFamily: ['Poppins'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    margin: '0 0 0 5px',
  },
  customCheckbox: {
    padding: 0 + '!important',
    '&.Mui-checked': {
      color: COLORS.green,
    },
  },
}));

export default styles;
