import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getWeatherData } from '../../api/weather';
import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const SubNavbar = (props) => {
  const classes = useStyles();
  const fontClasses = fontsStyles();
  const [weatherData, setWeatherData] = useState();
  // const [language, setLanguage] = useState('en');
  const [language] = useState('en');

  // const { t, i18n } = useTranslation();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      getWeatherData()
        .then(function (response) {
          setWeatherData(response.data);
        })
        .catch(() => {
          console.log('error obteniendo el clima');
        });
    }
  }, []);

  // const changeLanguage = () => {
  //   if (language === 'es') setLanguage('en');
  //   else setLanguage('es');
  // };

  return (
    <Box
      className={`${classes.navbarWrapper} ${props.isTransparent ? classes.transparentNavBar : ''}`}
    >
      <Box className={classes.navbarContent}>
        {weatherData && (
          <Box className={classes.wheatherBox}>
            <img
              src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
              alt={weatherData.weather[0].main}
              width='30'
              height='30'
              className={props.isTransparent ? '' : classes.wheatherImage}
            />
            <p
              className={clsx(
                fontClasses.smallText,
                `${props.isTransparent ? '' : classes.wheatherTextTransparent}`,
              )}
            >
              {Math.round(weatherData.main.temp)}°
            </p>
            <p
              className={clsx(
                fontClasses.smallText,
                `${props.isTransparent ? '' : classes.wheatherTextTransparent}`,
              )}
            >
              {weatherData.name}
            </p>
          </Box>
        )}
        {/* <p className={`${fontClasses.smallText} subNavText`}>|</p>
        <button
          className={clsx(fontClasses.smallText, classes.languageButton, 'subNavText')}
          onClick={changeLanguage}
        >
          {t('subNavbar.languageSelector')}
        </button> */}
      </Box>
    </Box>
  );
};

export default SubNavbar;
