import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants/';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    backgroundColor: COLORS.lightLime,
    height: '35px',
    width: '100%',
    display: 'none',
    justifyContent: 'center',
    zIndex: 500,
    position: 'fixed',
    top: 0,
    transition: 'background-color .1s linear',
    '& .subNavText': {
      color: COLORS.black,
    },
    [themeBreakpoints.up('md')]: {
      display: 'flex',
    },
  },
  transparentNavBar: {
    backgroundColor: 'transparent',
    '& .subNavText': {
      color: COLORS.white,
    },
  },
  navbarContent: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.SUB_TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      padding: '5px 10px',
    },
  },
  wheatherBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  languageButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    '&:hover': {
      color: COLORS.yellow,
    },
  },
  wheatherTextTransparent: {
    color: COLORS.black,
  },
  wheatherImage: {
    filter: 'brightness(80%)',
  },
}));

export default useStyles;
