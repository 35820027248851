import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import CustomPrimaryButton from '../../components/CustomButton';
import { PATHS, URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import styles from './styles';

const NotFoundPage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const isMobile = useMediaQuery({ query: `(min-width: ${breakpoints.values.md}px)` });
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={classes.pageNotFoundWrapper}>
      <div className={classes.pageNotFoundContentWrapper}>
        <div className={classes.pageNotFoundContent}>
          <img src={URL_IMAGES.URL_NOTFOUND} className={classes.slothImage} />
          <h2 className={clsx(fontClasses.h2, classes.firstParragraph)}>
            {isMobile ? t('notFoundPage.firstParragraph') : t('notFoundPage.firstParragraphMobile')}
          </h2>
          <p className={clsx(fontClasses.p, classes.secondParragraph)}>
            {t('notFoundPage.secondParragraph')}
          </p>
          <div>
            <CustomPrimaryButton
              label={t('notFoundPage.buttonReturn')}
              tertiary={true}
              url={PATHS.tour}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
