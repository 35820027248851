import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const TourSmallCard = ({ name, img, to, price, alt }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.cardWrapper}>
      <Link to={to} className={classes.linkWrapper}>
        <div className={classes.cardSubWrapper}>
          <img src={img} alt={alt} className={classes.tourImage} />
          <div>
            <p className={clsx(fontClasses.p, classes.titleTour)}>{name}</p>
            <p className={clsx(fontClasses.p, classes.priceTour)}>
              {t('tourCard.priceFrom')} <span className={fontClasses.semiBold}>${price}</span>{' '}
              {t('tourCard.pricePerson')}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TourSmallCard;
