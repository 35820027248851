import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: COLORS.white,
    width: '100%',
    margin: '200px 0 150px 0',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      marginTop: '100px',
    },
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  reservationBox: {
    width: '100%',
    boxShadow: ' 0px 10px 30px rgba(11, 11, 11, 0.10)',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  reservationContentBox: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  reservationImage: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  iconTextBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  icon: {
    color: COLORS.gray,
  },
  priceBox: {
    display: 'flex',
    gap: 15,
  },
  priceText: {
    fontSize: '14px',
  },
  stepContetBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  stepButtonBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: 20,
  },
  phoneInput: {
    '& .react-tel-input .form-control': {
      width: '100%',
      borderColor: COLORS.black,
      borderRadius: '10px',
      borderWidth: '1px',
      boxSizing: 'border-box',
      fontFamily: ['Poppins'].join(','),
      fontSize: '18px',
      padding: '15px 50px',
    },
    '& .react-tel-input .form-control:focus': {
      width: '100%',
      borderColor: COLORS.green,
      borderRadius: '10px',
      outline: `1px solid ${COLORS.green}`,
      fontFamily: ['Poppins'].join(','),
      fontSize: '18px',
    },
  },
  cantFindText: {
    cursor: 'pointer',
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: 300,
    '&:hover': {
      color: COLORS.orange,
    },
  },
  modalBox: {
    backgroundColor: COLORS.white,
    width: '450px',
    boxSizing: 'border-box',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    '& p': {
      textAlign: 'justify',
    },
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  radioInputBox: {
    width: '100%',
    border: '1px solid black',
    borderRadius: '10px',
    boxSizing: 'border-box',
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paymentBrands: {
    display: 'flex',
    gap: 5,
  },
  termsAndConditionsTexts: {
    width: '500px',
    fontSize: '12px',
    fontWeight: 300,
    textAlign: 'end',
  },
  stepperBox: {
    height: '100%',
  },
  loadingookingBox: {
    height: '100%',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
}));

export default styles;
