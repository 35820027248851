import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: 'white',
    width: '100%',
    marginTop: '200px',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      marginTop: '150px',
    },
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  tourTitleBox: {
    zIndex: 5000,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    [themeBreakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  tourTitle: {
    width: '800px',
    [themeBreakpoints.down('md')]: {
      width: '650px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  shareIconBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
    '&:hover $icon': {
      color: COLORS.orange,
    },
    '&:hover p': {
      color: COLORS.orange,
    },
  },
  icon: {
    color: COLORS.gray,
  },
  sliderBox: {
    position: 'relative',
  },
  mainImage: {
    width: '100%',
    height: '430px',
    borderRadius: '10px',
    objectFit: 'cover',
    marginBottom: '5px',
    transition: 'all 0.3s ease',
    [themeBreakpoints.down('sm')]: {
      height: '250px',
    },
  },
  thumbImage: {
    width: '100%',
    height: '100px',
    borderRadius: '10px',
    objectFit: 'cover',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  leftArrow: {
    position: 'absolute',
    left: -15,
    top: 38,
    zIndex: 10,
  },
  rightArrow: {
    position: 'absolute',
    right: -15,
    top: 38,
    zIndex: 10,
  },
  iconArrowButton: {
    backgroundColor: 'white !important',
    color: COLORS.green,
  },
  slider: {
    '& .slick-slide': {
      padding: '5px',
      boxSizing: 'border-box',
    },
  },
  reservationBox: {
    backgroundImage: `url('https://res.cloudinary.com/sealabs/image/upload/v1710698009/caribbeanadventures.cr/reservation_sloth_bjdwaa.svg')`,
    backgroundSize: '35%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    position: 'sticky',
    top: 150,
    width: '100%',
    boxShadow: ' 0px 10px 30px rgba(11, 11, 11, 0.10)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [themeBreakpoints.down('md')]: {
      position: 'unset',
      backgroundSize: '23%',
    },
    [themeBreakpoints.down('sm')]: {
      backgroundSize: '28%',
    },
  },
  bookTitle: {
    width: '200px',
    marginBottom: '10px',
  },
  bookText: {
    width: '200px',
    fontSize: '12px',
  },
  priceTourBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: 5,
    },
  },
  priceTour: {
    fontSize: '12px',
    fontWeight: 400,
  },
  priceTourBig: {
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 600,
    [themeBreakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  selectionBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  selectIcon: {
    fontSize: '24px',
    backgroundColor: COLORS.lightGreen,
    color: COLORS.black,
    padding: '8px',
    borderRadius: '50%',
  },
  datePicker: {
    '& .MuiButtonBase-root': {
      marginLeft: '8px',
      backgroundColor: `${COLORS.lightGreen} !important`,
      color: `${COLORS.black} !important`,
    },
  },
  arraivalShipsBox: {
    backgroundColor: COLORS.lightGreen,
    borderRadius: '10px',
    padding: '10px',
  },
  notFoundShipsBox: {
    backgroundColor: COLORS.red,
    borderRadius: '10px',
    padding: '10px',
  },
  arraivalShipsText: {
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '5px',
  },
  notFoundShipsText: {
    fontSize: '12px',
    color: COLORS.white,
    fontWeight: 500,
  },
  arraivalShipsList: {
    fontSize: '12px',
    fontWeight: 400,
  },
  tourInformationBox: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
  },
  specTourInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: '20px',
  },
  iconInfoText: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  infoSectionTitle: {
    marginBottom: '10px',
  },
  toursWrapper: {
    overflowX: 'hidden',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  toursContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      marginBottom: '100px',
      width: '90%',
    },
  },
  toursBoxSlider: {
    width: '100%',
  },
  toursBoxButtonSlider: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  departureBox: {
    display: ' flex',
    flexDirection: 'column',
    gap: 20,
  },
  itinerayIconBox: {
    display: 'flex',
    gap: 5,
    alignItems: 'flex-start',
  },
  itinerayicon: {
    color: COLORS.gray,
    marginTop: '3px',
  },
  intineraySubIconBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  mapContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    margin: '50px 0 50px 0',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginBottom: '50px',
    },
  },
  sectionContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginBottom: '50px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginBottom: '50px',
    },
  },
  skeletonLoading: {
    marginBottom: '100px',
  },
  shareTourBox: {
    backgroundColor: COLORS.white,
    display: 'flex',
    gap: 10,
    marginRight: '10px',
    [themeBreakpoints.down('md')]: {
      marginRight: '0px',
      marginTop: '10px',
    },
    [themeBreakpoints.down('sm')]: {
      marginRight: '10px',
      marginTop: '0px',
    },
  },
}));

export default styles;
