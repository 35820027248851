import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { COLORS } from '../constants';
import breakpoints from './breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const components = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.black,
          borderRadius: '10px',
          borderWidth: '1px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.green,
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderWidth: 1,
        backgroundColor: COLORS.white,
        '&.Mui-selected': {
          backgroundColor: `${COLORS.green} !important`,
          color: `${COLORS.white} !important`,
        },
      },
      today: {
        color: COLORS.gray,
        backgroundColor: `${COLORS.white} !important`,
        borderColor: `${COLORS.green} !important`,
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: {
        borderWidth: 1,
        backgroundColor: COLORS.white,
        '&.Mui-selected': {
          backgroundColor: `${COLORS.green} !important`,
          color: `${COLORS.white} !important`,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardInfo: {
        backgroundColor: `${COLORS.lightGreen} !important`,
        color: `${COLORS.gray} !important`,
        '& .MuiAlert-icon	': {
          color: `${COLORS.black} !important`,
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${COLORS.blue} !important`,
          color: `${COLORS.white} !important`,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '10px',
        [themeBreakpoints.down('sm')]: {
          borderRadius: '0px',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: `${COLORS.blue} !important`,
        color: `${COLORS.white} !important`,
        fontFamily: ['Poppins'].join(','),
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: `${COLORS.lightLime} !important`,
        '&.MuiStepIcon-root': {
          width: '35px',
          height: '35px',
          [themeBreakpoints.down('sm')]: {
            width: '25px',
            height: '25px',
          },
        },
        '.MuiStepIcon-text': {
          fill: COLORS.black,
          fontWeight: 600,
        },
        '&.Mui-active': {
          color: `${COLORS.green} !important`,
        },
        '&.Mui-active .MuiStepIcon-text': {
          fill: COLORS.white,
        },
        '&.Mui-completed': {
          color: `${COLORS.orange} !important`,
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        '.MuiStepConnector-lineVertical': { borderColor: COLORS.white },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        '.MuiStepLabel-label': {
          fontWeight: 600,
          fontSize: '24px',
          [themeBreakpoints.down('sm')]: {
            fontSize: '18px',
          },
          '&.Mui-active': {
            color: `${COLORS.black} !important`,
            fontWeight: 600,
          },
          '&.Mui-completed': {
            color: `${COLORS.black} !important`,
            fontWeight: 600,
          },
        },
      },
    },
  },
  MuiStepContent: {
    styleOverrides: {
      root: {
        borderColor: COLORS.white,
        paddingLeft: '0px',
        marginLeft: '0px',
        marginTop: '10px',
        [themeBreakpoints.down('sm')]: {
          paddingLeft: '0px',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '.MuiFormControlLabel-label': {
          fontSize: '16px',
        },
      },
    },
  },
  MuiPickersSlideTransition: {
    styleOverrides: {
      root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.MuiAccordion-root:before': {
          backgroundColor: COLORS.black,
          opacity: 1,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: 0,
        paddingTop: '15px',
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0 50px 10px 0',
      },
    },
  },
};

export default components;
