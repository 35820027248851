import { Checkbox } from '@mui/material';
import React from 'react';

import styles from './styles';

const CustomCheckbox = ({ label, value, changeFunction, className }) => {
  const classes = styles();

  return (
    <div className={`${classes.checkboxContainer} ${className || ''}`}>
      <Checkbox checked={value} onChange={changeFunction} className={classes.customCheckbox} />
      <p className={classes.filterText}>{label}</p>
    </div>
  );
};

export default CustomCheckbox;
