import { gql } from '@apollo/client';

const GET_CRUISES_BY_DATE = gql`
  query Query($filters: CruiseDateFiltersInput) {
    cruiseDates(filters: $filters) {
      data {
        attributes {
          cruise_id {
            data {
              id
              attributes {
                name
              }
            }
          }
          date
          eta
          etd
        }
      }
    }
  }
`;

export default GET_CRUISES_BY_DATE;
