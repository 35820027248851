import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CustomPrimaryButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import { PATHS } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const TourConfirmationSolution = () => {
  const query = useQuery();
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const queryResult = query.get('result');
  const queryCode = query.get('code');
  const queryTour = query.get('tour');
  const [bookingResult] = useState(queryResult === 'success');

  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const SuccessBooking = () => {
    return (
      <div className={classes.tourWrapper}>
        <div className={classes.tourContentWrapper}>
          <CheckOutlinedIcon className={classes.iconAffirmation} sx={{ fontSize: '90px' }} />
          <h2 className={clsx(fontClasses.h2, classes.titleAffirmation)}>
            {t('tourConfirmationPage.titleConfirmation')}
          </h2>
          <p className={clsx(fontClasses.p, classes.firstText)}>
            {t('tourConfirmationPage.firstParragraphConfirmation')}
          </p>
          <p className={clsx(fontClasses.p, classes.secondText)}>
            {t('tourConfirmationPage.secondParragraphConfirmation')}
          </p>
          <p className={clsx(fontClasses.p, classes.reservationTitle)}>
            {t('tourConfirmationPage.reservationCodeTitle')}
          </p>
          <h2 className={clsx(fontClasses.h2, classes.reservationCode)}>{queryCode}</h2>
        </div>
      </div>
    );
  };

  const FailBooking = () => {
    return (
      <div className={classes.tourWrapper}>
        <div className={classes.tourContentWrapper}>
          <CloseOutlinedIcon className={classes.iconNegation} sx={{ fontSize: '90px' }} />
          <h2 className={clsx(fontClasses.h2, classes.titleNegation)}>
            {t('tourConfirmationPage.titleNegation')}
          </h2>
          <p className={clsx(fontClasses.p, classes.firstText)}>
            {t('tourConfirmationPage.parragraphNegation')}
          </p>
          <div className={classes.buttonSpace}>
            <div className={classes.buttonInnerSpace}>
              <CustomPrimaryButton
                label={t('tourConfirmationPage.firstButtonNegation')}
                url={queryTour ? `${PATHS.tourCatalogue}/${queryTour}` : PATHS.tourCatalogue}
                fullWidth={true}
              />
            </div>
            <div className={classes.buttonInnerSpace}>
              <CustomPrimaryButton
                label={t('tourConfirmationPage.secondButtonNegation')}
                secondary={true}
                url={PATHS.contact}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Metatags
        title={
          bookingResult
            ? 'Caribbean Adventures | Booking Success'
            : 'Caribbean Adventures | Booking Error'
        }
        description={bookingResult ? 'Your booking is complete!' : 'Something happened!'}
      />
      {bookingResult && <SuccessBooking />}
      {!bookingResult && <FailBooking />}
    </>
  );
};

export default TourConfirmationSolution;
