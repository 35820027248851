import Slider from '@mui/material/Slider';
import React, { useState } from 'react';

import styles from './styles';

function sliderValueText(value) {
  return `$${value}`;
}

const CustomSlider = ({ min, max, currentValues, changeFunction }) => {
  const [visualValues, setVisualValues] = useState([currentValues[0], currentValues[1]]);
  const classes = styles();

  const handleValueChange = (event, newValue) => {
    setVisualValues(newValue);
  };

  return (
    <div className={classes.currencySliderContainer}>
      <p
        className={`${classes.filterText} ${classes.currencyText} left`}
      >{`$${visualValues[0]}`}</p>
      <p
        className={`${classes.filterText} ${classes.currencyText} right`}
      >{`$${visualValues[1]}`}</p>
      <Slider
        className={classes.currencySlider}
        getAriaLabel={() => 'Slider range'}
        value={visualValues}
        onChange={handleValueChange}
        onChangeCommitted={changeFunction}
        valueLabelDisplay='off'
        getAriaValueText={sliderValueText}
        min={min}
        max={max}
        track={false}
        disableSwap
      />
    </div>
  );
};

export default CustomSlider;
