import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: COLORS.white,
    width: '100%',
    height: '800px',
    marginTop: '110px',
    backgroundImage: `url('https://res.cloudinary.com/sealabs/image/upload/v1711759893/caribbeanadventures.cr/luis-diego-aguilar-bSJWEfOrHps-unsplash_yr5dzn.jpg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    position: 'relative',
    [themeBreakpoints.down('md')]: {
      height: '800px',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    [themeBreakpoints.down('sm')]: {
      marginTop: '90px',
      height: '600px',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
  },
  titleText: {
    width: '400px',
    textAlign: 'center',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  headerContentWrapper: {
    position: 'relative',
    left: 0,
    right: 0,
    top: -200,
    margin: '0 auto',
    backgroundColor: COLORS.white,
    marginBottom: '-200px',
    borderRadius: '10px',
    width: '800px',
    boxShadow: ' 0px 10px 30px rgba(11, 11, 11, 0.10)',
    padding: '50px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [themeBreakpoints.down('sm')]: {
      padding: '25px',
      width: '90%',
      margin: '0 auto',
      top: -200,
      marginBottom: '-200px',
    },
  },
  cruiseWrapper: {
    backgroundColor: COLORS.white,
    width: '100%',
    marginTop: '200px',
    marginBottom: '200px',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      marginTop: '100px',
      marginBottom: '100px',
    },
  },
  cruiseContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  seasonCruiseText: {
    width: '80%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  calendarBox: {
    position: 'relative',
    marginTop: '20px',
    boxShadow: ' 0px 10px 30px rgba(11, 11, 11, 0.10)',
    padding: '50px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    [themeBreakpoints.down('sm')]: {
      padding: '25px',
    },
  },
  datePicker: {
    width: '100%',
    '& .MuiPickersCalendarHeader-root': {
      padding: 0,
    },
    '&.MuiDateCalendar-root': {
      maxHeight: 'unset !important',
      height: 'auto',
    },
    '& .MuiPickersSlideTransition-root': {
      height: 'auto',
      aspectRatio: '9 / 8',
    },
    '& .MuiPickersDay-root': {
      backgroundColor: COLORS.white,
      borderRadius: '0px',
      margin: '0px',
      border: `solid 1px ${COLORS.stroke} !important`,
      width: '100%',
      height: 'auto',
      aspectRatio: '1 / 1',
      '&.Mui-selected': {
        backgroundColor: `${COLORS.orange} !important`,
        color: `${COLORS.white} !important`,
        border: `solid 1px ${COLORS.orange} !important`,
      },
      '&.MuiPickersDay-dayOutsideMonth': {
        backgroundColor: `${COLORS.lightGray} !important`,
      },
      '&.MuiPickersDay-weekContainer': {
        backgroundColor: `${COLORS.orange} !important`,
      },
      '& .MuiPickersFadeTransitionGroup-root': {
        backgroundColor: 'red',
      },
    },
    '& .MuiYearCalendar-root': {
      width: '100%',
    },

    '& .MuiDayCalendar-weekContainer': {
      margin: 0,
      padding: 0,
    },
    '& .MuiDayCalendar-weekDayLabel': {
      width: '100%',
      height: '50px',
      fontWeight: 600,
    },
  },
  badgeButton: {
    width: 'calc(100% /7)',
    height: 'auto',
    aspectRatio: '1 / 1',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    '& .MuiBadge-badge': {
      top: 15,
      right: 15,
    },
  },
  cruisesDatesBox: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  icon: {
    color: COLORS.black,
    marginTop: '5px',
  },
  cruisesBox: {
    display: 'flex',
    gap: 10,
  },
  cruiseTitle: {
    fontSize: '18px',
  },
  cruiseInformation: {
    fontSize: '14px',
  },
  emptyCruiseBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emptyContentCruiseBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
  },
  noCruisesText: {
    width: '70%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  frogImage: {
    width: '120px',
    height: 'auto',
    position: 'absolute',
    bottom: -20,
    right: -15,
    transform: 'translate(50%, -50%) scale(1)',
    [themeBreakpoints.down('sm')]: {
      transform: 'translate(50%, -50%) scale(0.1)',
      display: 'none',
    },
  },
  faqWrapper: {
    backgroundColor: COLORS.white,
    width: '100%',
    marginBottom: '150px',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '100px',
    },
  },
  faqContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  questionTitle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  aboutDescriptionSkeletonLong: {
    width: '100%',
    height: '130px !important',
    marginBottom: '16px',
  },
  faqSkeleton: {
    width: '220px',
    height: '24px !important',
    transform: 'none !important',
  },
  cruisesSkeletonTitle: {
    width: '220px',
    height: '25px !important',
    transform: 'none !important',
    [themeBreakpoints.up('md')]: {
      width: '160px',
    },
    [themeBreakpoints.up('lg')]: {
      width: '280px',
    },
  },
  cruisesSkeletonIcon: {
    marginTop: '5px',
    width: '30px',
    height: '30px !important',
  },
  cruisesSkeletonSubTitle: {
    width: '110px',
    height: '18px !important',
    transform: 'none !important',
    margin: '4px 0 !important',
  },
  cruisesSkeletonTime: {
    width: '180px',
    height: '16px !important',
    transform: 'none !important',
    margin: '5px 0 !important',
  },
}));

export default styles;
