import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    textDecoration: 'none',
  },
  cardWrapper: {
    width: '100%',
    height: '300px',
    boxShadow: ' 0px 0px 20px 5px rgba(159, 159, 159, 0.20)',
    borderRadius: '10px',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    [themeBreakpoints.down('md')]: {
      justifyContent: 'center',
      height: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      justifyContent: 'center',
      '&:hover': {
        transform: 'scale(1)',
      },
      height: '100%',
    },
  },
  cardSubWrapper: {
    display: 'flex',
    [themeBreakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  tourImageBox: {
    width: '300px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  tourImage: {
    height: '300px',
    width: '300px',
    objectFit: 'cover',
    objectPosition: 'center center',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      borderBottomLeftRadius: '0px',
    },
  },
  infoTourBox: {
    width: 'calc(100% - 300px)',
    height: '308px',
    boxSizing: 'border-box',
    padding: '30px',
    display: 'flex',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      flexDirection: 'column',
      gap: 15,
    },
  },
  infoTour: {
    width: '415px',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  priceTourBox: {
    width: 'calc(100% - 415px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: 5,
    },
  },
  titleTour: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '20px',
    marginBottom: '10px',
  },
  priceTour: {
    fontSize: '14px',
    fontWeight: 400,
  },
  titleTourBig: {
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 600,
    [themeBreakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  tourDetailsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  tagText: {
    width: 'auto',
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    padding: '5px 15px',
    borderRadius: '50px',
    fontSize: '12px',
    fontWeight: 500,
  },
  descriptionText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  descriptionTextOverflow: {
    display: '-webkit-box',
    WebkitLineClamp: 8,
    lineClamp: 8,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  descriptionTextOverflowWithTag: {
    display: '-webkit-box',
    WebkitLineClamp: 6,
    lineClamp: 6,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  descriptionIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  descriptionIcon: {
    color: COLORS.black,
    fontSize: '10px',
  },
}));

export default useStyles;
