import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS, URL_IMAGES } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: 'white',
    width: '100%',
    height: '950px',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url('https://res.cloudinary.com/sealabs/image/upload/v1709433892/caribbeanadventures.cr/headerBackground_hh7m4b.webp')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [themeBreakpoints.down('md')]: {
      height: '800px',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    [themeBreakpoints.down('sm')]: {
      height: '600px',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '300px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      marginTop: '300px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '200px',
    },
  },
  searchBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      gap: 0,
    },
  },
  headerText: {
    fontWeight: 700,
    color: COLORS.white,
    width: '500px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchBox: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRadius: '10px',
    height: '60px',
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    gap: 10,
    [themeBreakpoints.down('sm')]: {
      width: '70%',
      height: '50px',
    },
  },
  toursWrapper: {
    overflowX: 'hidden',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  toursContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    margin: '50px 0 200px 0',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      margin: '0px 0 50px 0',
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  toursText: {
    width: '600px',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  toursBoxSlider: {
    width: '100%',
  },
  experienciesSlider: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  experienciesSliderWrapper: {
    width: '100%',
  },
  tripAdvisorWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tripAdvisorElement: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    alignSelf: 'flex-end',
    marginBottom: '10px',
  },
  tripAdvisorDisclaimer: {
    fontFamily: ['Poppins'].join(','),
    fontWeight: '400',
    fontSize: '12px',
    color: COLORS.gray,
    margin: '0',
    textAlign: 'end',
  },
  toursBoxButtonSlider: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px',
  },
  slider: {
    '& .slick-slide': {
      boxSizing: 'border-box',
      padding: '5px',
    },
  },
  experienceSlider: {
    '& .slick-slide': {
      boxSizing: 'border-box',
      padding: '10px',
    },
  },
  teamWrapper: {
    backgroundColor: COLORS.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  teamContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '25px',
    margin: '200px 0 100px 0',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      margin: '100px 0 50px 0',
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  imageBox: {
    position: 'relative',
    width: '480px',
    height: '540px',
    borderEndEndRadius: '50px',
    borderTopLeftRadius: '50px',
    backgroundImage: `url(${URL_IMAGES.URL_TEAM})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [themeBreakpoints.down('md')]: {
      width: '100%',
      height: '400px',
    },
  },
  butterflyImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    transform: 'translate(50%, -50%) scale(0.4) rotate(15deg)',
    [themeBreakpoints.down('md')]: {
      right: 15,
      transform: 'translate(48%, -50%) scale(0.2) rotate(15deg)',
    },
  },
  tripAdvisorImage: {
    maxWidth: '101px',
  },
  titles: {
    whiteSpace: 'pre-wrap',
  },
  infomationTeam: {
    [themeBreakpoints.down('sm')]: {
      paddingTop: '10px',
      width: '100%',
    },
  },
  teamText: {
    marginBottom: '20px',
  },
  experiencesText: {
    marginBottom: '20px',
    width: '55%',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  provinceImageBackward: {
    transform: 'rotate(180deg)',
  },
  provinceImage: {
    overflowY: 'hidden',
    width: '100%',
    height: '100%',
  },
  provinceMainWrapper: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    backgroundColor: COLORS.lightYellow,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  provinceContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '50px 0 50px 0',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  provinceInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '60px',
  },
  provinceTitle: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
  provinceIcon: {
    color: COLORS.green,
    border: `solid 4px ${COLORS.green}`,
    borderRadius: '50%',
    padding: '10px',
  },
  provinceInfoIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  paragraph: {
    textAlign: 'justify',
  },
  provinceParagraph: {
    textAlign: 'justify',
    width: '600px',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
  },
  experienceWrapper: {
    backgroundColor: COLORS.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  experienceContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '25px',
    margin: '100px 0 150px 0',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      margin: '50px 0 100px 0',
      width: '90%',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  },
  noResultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },
  noResultsSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  noResultsIcon: {
    color: COLORS.gray,
  },
}));

export default styles;
