import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS, DIMENSIONS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  tourWrapper: {
    width: '100%',
    margin: '200px 0 150px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      margin: '150px 0 100px 0',
    },
  },
  tourContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  iconAffirmation: {
    color: COLORS.green,
    border: `solid 4px ${COLORS.green}`,
    borderRadius: '50%',
    padding: '10px',
    marginBottom: '25px',
  },
  titleAffirmation: {
    color: COLORS.green,
    fontSize: '36px',
    marginBottom: '30px',
    textAlign: 'center',
    [themeBreakpoints.down('sm')]: {
      whiteSpace: 'pre-wrap',
    },
  },
  firstText: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    [themeBreakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
  secondText: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '55%',
    textAlign: 'center',
    [themeBreakpoints.down('md')]: {
      width: '85%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reservationTitle: {
    fontWeight: 700,
  },
  reservationCode: {
    marginTop: '20px',
    backgroundColor: COLORS.lightGreen,
    padding: '50px 80px 50px 80px',
    borderRadius: '10px',
    letterSpacing: '18px',
  },
  iconNegation: {
    color: COLORS.red,
    border: `solid 4px ${COLORS.red}`,
    borderRadius: '50%',
    padding: '10px',
    marginBottom: '25px',
  },
  titleNegation: {
    color: COLORS.red,
    fontSize: '36px',
    marginBottom: '30px',
    textAlign: 'center',
    [themeBreakpoints.down('sm')]: {
      whiteSpace: 'pre-wrap',
    },
  },
  buttonSpace: {
    width: '30%',
    [themeBreakpoints.down('md')]: {
      width: '40%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonInnerSpace: {
    paddingTop: '20px',
  },
}));

export default styles;
