import { gql } from '@apollo/client';

const ADD_CONTACT_FORM_MUTATION = gql`
  mutation AddContactForm(
    $email: String!
    $full_name: String!
    $subject: String!
    $message: String!
  ) {
    createContactForm(
      data: { email: $email, full_name: $full_name, subject: $subject, message: $message }
    ) {
      data {
        id
        attributes {
          full_name
          subject
          email
          message
        }
      }
    }
  }
`;

export default ADD_CONTACT_FORM_MUTATION;
