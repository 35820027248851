import Box from '@mui/material/Box';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const CustomPrimaryButton = ({
  label,
  icon,
  url,
  disabled,
  onClick,
  fullWidth,
  secondary,
  extraClasses,
  tertiary,
  state,
  submit,
}) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  const TypeButtonClass = () => {
    let classStyle;

    if (secondary && !tertiary) {
      classStyle = disabled ? classes.secondaryStyleDisabled : classes.secondaryStyle;
    } else if (tertiary && !secondary) {
      classStyle = disabled ? classes.tertiaryStyleDisabled : classes.tertiaryStyle;
    } else {
      classStyle = disabled ? classes.mainStyleDisabled : classes.mainStyle;
    }
    return classStyle;
  };

  const ButtonStructure = () => {
    return (
      <button
        className={clsx(classes.buttonStructure, extraClasses, TypeButtonClass())}
        onClick={onClick || null}
        disabled={disabled}
        style={{ width: fullWidth ? '100%' : 'auto' }}
        type={submit ? 'submit' : 'button'}
      >
        <p
          className={clsx(
            fontClasses.p,
            !disabled ? classes.buttonText : classes.buttonTextDisabled,
          )}
        >
          {label}
        </p>
        {icon && (
          <Box className={!disabled ? classes.iconButtonBox : classes.iconButtonBoxDisabled}>
            {icon}
          </Box>
        )}
      </button>
    );
  };

  return (
    <>
      {url && (
        <Link to={url} style={{ textDecoration: 'none', width: '100%' }} state={state || null}>
          <ButtonStructure />
        </Link>
      )}
      {!url && <ButtonStructure />}
    </>
  );
};

export default CustomPrimaryButton;
