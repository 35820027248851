import 'mapbox-gl/dist/mapbox-gl.css';

import { useLazyQuery, useQuery } from '@apollo/client';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IosShareIcon from '@mui/icons-material/IosShare';
import MapIcon from '@mui/icons-material/Map';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  Select,
  Skeleton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import Slider from 'react-slick';

import CustomButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import TourSmallCard from '../../components/TourSmallCard/tourSmallCard';
import { COLORS, PATHS } from '../../constants';
import { formatCancelationPolicy } from '../../helpers/interfaces/clientParamsInterface';
import { formatCruisesByDate } from '../../helpers/interfaces/cruisesInterface';
import { formatFeaturedTours, formatTours } from '../../helpers/interfaces/tourInterface';
import { GET_CANCELATION_POLICY } from '../../queries/clientParams';
import GET_CRUISES_BY_DATE from '../../queries/cruises';
import { GET_TOUR_BY_ID, SIMILAR_TOURS } from '../../queries/tours';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

dayjs.extend(utc);
dayjs.extend(timezone);

const HomePage = () => {
  const classes = styles();
  const navigate = useNavigate();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;
  const onlinePayment = process.env.REACT_APP_ONLINE_PAYMENT === 'true';
  const isDesktop = useMediaQuery({ query: '(min-width: 1100px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [tourInfo, setTourInfo] = useState();
  const [cancelationPolicy, setCancelationPolicy] = useState();
  const { id } = useParams();
  const [getTourData, { data, loading, error }] = useLazyQuery(GET_TOUR_BY_ID, {
    variables: {
      tourId: parseInt(id),
    },
  });

  const { data: cancelationData } = useQuery(GET_CANCELATION_POLICY);

  useEffect(() => {
    if (!isNaN(id)) {
      getTourData();
    } else {
      navigate(PATHS.tourCatalogue);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      if (data.tour.data) {
        setTourInfo(formatTours(data));
      } else {
        navigate(PATHS.tourCatalogue);
      }
    }
    if (error && !loading) {
      navigate(PATHS.tourCatalogue);
    }
  }, [data]);

  useEffect(() => {
    if (cancelationData) {
      setCancelationPolicy(formatCancelationPolicy(cancelationData));
    }
  }, [cancelationData]);

  useEffect(() => {
    if (!window.location.hash) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'share-popper' : undefined;

    return (
      <div className={classes.headerWrapper}>
        <div className={classes.headerContentWrapper}>
          <div className={classes.tourTitleBox}>
            <h2 className={clsx(fontClasses.h2, classes.tourTitle)}>{tourInfo.name}</h2>
            <div className={classes.shareIconBox} aria-describedby={id} onClick={handleClick}>
              <IosShareIcon sx={{ fontSize: '18px' }} className={classes.icon} />
              <p className={fontClasses.p}>{t('tourPage.share')}</p>
            </div>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement={!isMobile && !isDesktop ? 'bottom-end' : 'left'}
            >
              <div className={classes.shareTourBox}>
                <FacebookShareButton url={window.location.href} title={t('tourPage.shareMessage')}>
                  <FacebookIcon size={30} round />
                </FacebookShareButton>
                <TelegramShareButton url={window.location.href} title={t('tourPage.shareMessage')}>
                  <TelegramIcon size={30} round />
                </TelegramShareButton>
                <TwitterShareButton url={window.location.href} title={t('tourPage.shareMessage')}>
                  <XIcon size={30} round />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href} title={t('tourPage.shareMessage')}>
                  <WhatsappIcon size={30} round />
                </WhatsappShareButton>
              </div>
            </Popper>
          </div>
          <Grid container spacing={4}>
            <Grid lg={8} md={12} sm={12} xs={12}>
              <GalleryBox />
              {isDesktop && <TourInformation />}
            </Grid>
            <Grid lg={4} md={12} sm={12} xs={12}>
              <ReservationBox />
              {!isDesktop && <TourInformation />}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const GalleryBox = () => {
    let sliderRef = useRef(null);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const next = () => {
      sliderRef.slickNext();
    };
    const previous = () => {
      sliderRef.slickPrev();
    };

    const selectImage = (index) => {
      setGalleryIndex(index);
    };

    return (
      <div>
        <img
          src={tourInfo.images[galleryIndex].url}
          alt={tourInfo.images[galleryIndex].alt}
          className={classes.mainImage}
        />
        <div className={classes.sliderBox}>
          {((isDesktop && tourInfo.images.length > 3) ||
            (!isDesktop && tourInfo.images.length > 2)) && (
            <>
              <div className={classes.leftArrow}>
                <IconButton
                  className={classes.iconArrowButton}
                  color='primary'
                  size='small'
                  onClick={previous}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <div className={classes.rightArrow}>
                <IconButton
                  className={classes.iconArrowButton}
                  color='primary'
                  size='small'
                  onClick={next}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </>
          )}
          <Slider
            {...settings}
            ref={(slider) => {
              sliderRef = slider;
            }}
            className={classes.slider}
          >
            {tourInfo.images.map((item, index) => (
              <img
                key={`gallery-item-${index}`}
                src={item.url}
                className={classes.thumbImage}
                alt={item.alt}
                onClick={() => selectImage(index)}
              />
            ))}
          </Slider>
        </div>
      </div>
    );
  };

  const ReservationBox = () => {
    const [quantity, setQuantity] = useState(1);
    const [date, setDate] = useState(dayjs());
    const [cruiseByDate, setCruiseByDate] = useState();

    const {
      data: cruisesDates,
      loading: loadingCruises,
      error: errorCruises,
    } = useQuery(GET_CRUISES_BY_DATE, {
      variables: {
        filters: {
          date: {
            eq: date.format('YYYY-MM-DD'),
          },
        },
      },
    });

    useEffect(() => {
      if (cruisesDates) {
        if (cruisesDates.cruiseDates.data.length > 0) {
          setCruiseByDate(formatCruisesByDate(cruisesDates).cruisesNameList);
        } else {
          setCruiseByDate([]);
        }
      }
    }, [cruisesDates]);

    const handleChange = (event) => {
      setQuantity(event.target.value);
    };

    return (
      <div className={classes.reservationBox}>
        <div>
          <h4 className={clsx(fontClasses.h4, classes.bookTitle)}>{t('tourPage.bookTitle')}</h4>
          <p className={clsx(fontClasses.p, classes.bookText)}>{t('tourPage.bookText')}</p>
        </div>
        <div className={classes.priceTourBox}>
          <p className={clsx(fontClasses.p, classes.priceTour)}>{t('tourPage.priceFrom')}</p>
          <p className={clsx(fontClasses.p, classes.priceTour)}>
            <span className={classes.priceTourBig}>${tourInfo.price}</span>
          </p>
          <p className={clsx(fontClasses.p, classes.priceTour)}>{t('tourPage.pricePerson')}</p>
        </div>
        <div className={classes.selectionBox}>
          <FormControl>
            <Select
              id='demo-simple-select'
              value={quantity}
              onChange={handleChange}
              displayEmpty
              MenuProps={{
                disableScrollLock: true,
              }}
              startAdornment={
                <InputAdornment position='end'>
                  <PeopleAltOutlinedIcon
                    size={20}
                    className={classes.selectIcon}
                    sx={{ marginRight: '10px' }}
                  />
                </InputAdornment>
              }
            >
              <MenuItem value={1}>{`1 ${t('tourPage.person')}`}</MenuItem>
              {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                <MenuItem
                  key={`people-${index}`}
                  value={item}
                >{`${item} ${t('tourPage.people')}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <DesktopDatePicker
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            disablePast
            orientation='portrait'
            autoFocus={false}
            timezone='America/Costa_Rica'
            className={classes.datePicker}
            slotProps={{
              inputAdornment: {
                position: 'start',
                id: 'calendarButton',
              },
            }}
            value={date}
            onChange={(newValue) => setDate(newValue)}
          />
          {!cruiseByDate && <Skeleton variant='rounded' width={'100%'} height={100} />}
          {loadingCruises && <Skeleton variant='rounded' width={'100%'} height={100} />}
          {cruiseByDate && cruiseByDate.length > 0 && !loadingCruises && !errorCruises && (
            <div className={classes.arraivalShipsBox}>
              <p className={clsx(fontClasses.p, classes.arraivalShipsText)}>
                {t('tourPage.followingShips')}
              </p>
              {cruiseByDate.map((item, index) => (
                <p
                  key={`ships-${index}`}
                  className={clsx(fontClasses.p, classes.arraivalShipsList)}
                >{`• ${item.name}`}</p>
              ))}
            </div>
          )}
          {cruiseByDate && cruiseByDate.length === 0 && !loadingCruises && (
            <div className={classes.notFoundShipsBox}>
              <p className={clsx(fontClasses.p, classes.notFoundShipsText)}>
                {t('tourPage.notFounShips')}
              </p>
            </div>
          )}
        </div>
        <div className={classes.selectionBox}>
          <CustomButton
            label={t('tourPage.payLater')}
            url={`${PATHS.tourConfirmation}?tourId=${id}&date=${date.format('YYYY-MM-DD')}&quantity=${quantity}&payment=later`}
            fullWidth
            state={{
              tourInfo,
              cruiseByDate,
              paymentType: 'later',
            }}
            secondary
          />
          {onlinePayment && (
            <CustomButton
              label={t('tourPage.bookTitle')}
              url={`${PATHS.tourConfirmation}?tourId=${id}&date=${date.format('YYYY-MM-DD')}&quantity=${quantity}&payment=card`}
              fullWidth
              state={{
                tourInfo,
                cruiseByDate,
                paymentType: 'card',
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const TourInformation = () => {
    return (
      <div className={classes.tourInformationBox}>
        <div>
          <h4 className={clsx(fontClasses.h4, classes.infoSectionTitle)}>
            {t('tourPage.overview')}
          </h4>
          <p className={clsx(fontClasses.p)}>{tourInfo.overview}</p>
          <div className={classes.specTourInfo}>
            {tourInfo.duration && (
              <div className={classes.iconInfoText}>
                <AccessTimeIcon sx={{ fontSize: '18px' }} className={classes.icon} />
                <p className={fontClasses.p}>
                  {tourInfo.duration}{' '}
                  {tourInfo.duration > 1 ? t('tourPage.hours') : t('tourPage.hour')}
                </p>
              </div>
            )}
            {tourInfo.minCapacity && (
              <div className={classes.iconInfoText}>
                <PeopleAltOutlinedIcon sx={{ fontSize: '18px' }} className={classes.icon} />
                <p className={fontClasses.p}>
                  {tourInfo.minCapacity}{' '}
                  {tourInfo.minCapacity > 1 ? t('tourPage.people') : t('tourPage.person')}
                </p>
              </div>
            )}
            {tourInfo.languagues && (
              <div className={classes.iconInfoText}>
                <PublicOutlinedIcon sx={{ fontSize: '18px' }} className={classes.icon} />
                <p className={fontClasses.p}>
                  {tourInfo.languagues.map((item, index, { length }) =>
                    index === length - 1 ? `${item}` : `${item} - `,
                  )}
                </p>
              </div>
            )}
            {tourInfo.isTicket && (
              <div className={classes.iconInfoText}>
                <SmartphoneIcon sx={{ fontSize: '18px' }} className={classes.icon} />
                <p className={fontClasses.p}>{t('tourPage.eTicket')}</p>
              </div>
            )}
          </div>
        </div>
        {tourInfo.included && (
          <div>
            <h4 className={clsx(fontClasses.h4, classes.infoSectionTitle)}>
              {t('tourPage.included')}
            </h4>
            {tourInfo.included &&
              tourInfo.included.map((item, index) => (
                <p key={`included-${index}`} className={fontClasses.p}>{`✓ ${item}`}</p>
              ))}
          </div>
        )}
        {tourInfo.itineray && (
          <div>
            <h4 className={clsx(fontClasses.h4, classes.infoSectionTitle)}>
              {t('tourPage.depandret')}
            </h4>
            <div className={classes.departureBox}>
              {tourInfo.itineray.departure && (
                <div>
                  <div className={classes.itinerayIconBox}>
                    <PlaceIcon sx={{ fontSize: '18px' }} className={classes.itinerayicon} />
                    <div className={classes.itineraySubIconBox}>
                      <p className={fontClasses.p}>
                        <span className={fontClasses.semiBold}>{t('tourPage.departure')}: </span>
                        {tourInfo.itineray.departure[0]}
                      </p>
                      <p className={fontClasses.p}>{tourInfo.itineray.departure[1]}</p>
                    </div>
                  </div>
                </div>
              )}
              {tourInfo.itineray.stops && (
                <div>
                  <div className={classes.itinerayIconBox}>
                    <MapIcon sx={{ fontSize: '18px' }} className={classes.itinerayicon} />
                    <div className={classes.itineraySubIconBox}>
                      <p className={fontClasses.p}>
                        <span className={fontClasses.semiBold}>{t('tourPage.stops')}:</span>
                      </p>
                      <ol>
                        {tourInfo.itineray.stops.map((item, index) => (
                          <>
                            <li key={`stops-${index}`} className={fontClasses.p}>
                              {item.title}
                            </li>
                            <ul>
                              {item.description.map((desc, index) => (
                                <li key={`stops-description-${index}`} className={fontClasses.p}>
                                  {desc}
                                </li>
                              ))}
                            </ul>
                          </>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
              )}
              {tourInfo.itineray.return && (
                <div>
                  <div className={classes.itinerayIconBox}>
                    <PlaceIcon sx={{ fontSize: '18px' }} className={classes.itinerayicon} />
                    <div className={classes.itineraySubIconBox}>
                      <p className={fontClasses.p}>
                        <span className={fontClasses.semiBold}>{t('tourPage.return')}: </span>
                        {tourInfo.itineray.return[0]}
                      </p>
                      <p className={fontClasses.p}>{tourInfo.itineray.return[1]}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const MapLocation = () => {
    return (
      <div className={classes.toursWrapper}>
        <div className={classes.mapContentWrapper}>
          {tourInfo.location && (
            <Map
              mapboxAccessToken={mapboxKey}
              initialViewState={{
                longitude: tourInfo.location.longitude,
                latitude: tourInfo.location.latitude,
                zoom: 14,
              }}
              style={{ width: '100%', height: '400px', borderRadius: '10px' }}
              mapStyle='mapbox://styles/jeanvegad/clu6e5rwr03jk01pb1oo9gkh0'
              attributionControl={false}
              logoPosition='bottom-right'
            >
              <Marker
                longitude={tourInfo.location.longitude}
                latitude={tourInfo.location.latitude}
                anchor='bottom'
              >
                <PlaceIcon style={{ fontSize: '40px', color: COLORS.green }} />
              </Marker>
            </Map>
          )}
        </div>
      </div>
    );
  };

  const CancelationPolicy = () => {
    return (
      <div className={classes.toursWrapper}>
        <div className={classes.sectionContentWrapper}>
          <h4 className={clsx(fontClasses.h4, classes.infoSectionTitle)}>
            {t('tourPage.cancelation')}
          </h4>
          {cancelationPolicy.policy && (
            <div>
              <div className={classes.itinerayIconBox}>
                <div className={classes.itineraySubIconBox}>
                  <ol>
                    {cancelationPolicy.policy.map((item, index) => (
                      <>
                        <li key={`policy-${index}`} className={fontClasses.p}>
                          {item.title}
                        </li>
                        <ul>
                          {item.description.map((desc, index) => (
                            <li key={`policy-description-${index}`} className={fontClasses.p}>
                              {desc}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const SimilarExperiences = () => {
    const [similarToursList, setSimilarToursList] = useState();
    const { data: similarToursData } = useQuery(SIMILAR_TOURS, {
      variables: {
        page: 1,
        filters: {
          id: {
            ne: id,
          },
        },
      },
    });

    useEffect(() => {
      if (similarToursData) {
        setSimilarToursList(formatFeaturedTours(similarToursData));
      }
    }, [similarToursData]);

    let sliderRef = useRef(null);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const next = () => {
      sliderRef.slickNext();
    };
    const previous = () => {
      sliderRef.slickPrev();
    };

    const skeletonsList = [1, 2, 3];

    return (
      <div className={classes.toursWrapper}>
        <div className={classes.toursContentWrapper}>
          <div className={classes.toursBoxSlider}>
            <div className={classes.toursBoxButtonSlider}>
              <h4 className={clsx(fontClasses.h4)}>{t('homePage.toursTitle')}</h4>
              <div>
                <IconButton aria-label='previus' onClick={previous}>
                  <ChevronLeftIcon sx={{ fontSize: '20px', color: COLORS.black }} />
                </IconButton>
                <IconButton aria-label='next' onClick={next}>
                  <ChevronRightIcon sx={{ fontSize: '20px', color: COLORS.black }} />
                </IconButton>
              </div>
            </div>
            <Slider
              {...settings}
              ref={(slider) => {
                sliderRef = slider;
              }}
              className={classes.slider}
            >
              {!similarToursList &&
                skeletonsList.map((index) => {
                  return (
                    <Skeleton
                      key={index}
                      variant='rectangular'
                      height={'425px'}
                      sx={{
                        borderRadius: '10px',
                        width: { xs: '100%', sm: '100%', md: 350, lg: 350 },
                      }}
                    />
                  );
                })}
              {similarToursList &&
                similarToursList.map((tour, index) => {
                  return (
                    <TourSmallCard
                      key={index}
                      name={tour.name}
                      img={tour.img}
                      to={`${PATHS.tour}/${tour.id}`}
                      price={tour.price}
                      alt={tour.alt}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
    );
  };

  const LoadingSkeleton = () => {
    return (
      <div className={classes.headerWrapper}>
        <div className={clsx(classes.headerContentWrapper, classes.skeletonLoading)}>
          <Grid container spacing={4}>
            <Grid lg={8} md={12} sm={12} xs={12}>
              <Skeleton variant='rounded' width={'100%'} height={isDesktop ? 400 : 400} />
            </Grid>
            <Grid lg={4} md={12} sm={12} xs={12}>
              <Skeleton variant='rounded' width={'100%'} height={isDesktop ? 400 : 300} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <>
      {tourInfo && (
        <>
          <Metatags
            title={`Caribbean Adventures | ${tourInfo.name}`}
            description='Enjoy your experience.'
          />
          <Header />
          <MapLocation />
          {cancelationPolicy && <CancelationPolicy />}
          {<SimilarExperiences />}
        </>
      )}
      {!tourInfo && <LoadingSkeleton />}
    </>
  );
};

export default HomePage;
