import 'mapbox-gl/dist/mapbox-gl.css';

import { useMutation, useQuery } from '@apollo/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlaceIcon from '@mui/icons-material/Place';
import { Alert, AlertTitle, Grid, Snackbar, TextField } from '@mui/material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import * as yup from 'yup';

import CustomPrimaryButton from '../../components/CustomButton';
import Metatags from '../../components/MetaTags/metatags';
import { COLORS, URL_IMAGES } from '../../constants';
import { GET_CLIENT_INFORMATION_DATA } from '../../queries/clientParams';
import ADD_CONTACT_FORM_MUTATION from '../../queries/contactForm';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const ContactPage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [locationLatitude, setLocationLatitude] = useState(9.991884009565464);
  const [locationLongitude, setLocationLongitude] = useState(-83.02584194703437);

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = (event) => {
    setOpenSuccess(false);
  };

  const { data: contactInformationObject } = useQuery(GET_CLIENT_INFORMATION_DATA, {
    variables: {
      page: 1,
    },
  });

  useEffect(() => {
    if (contactInformationObject) {
      console.log(contactInformationObject);
      setLocationLatitude(
        contactInformationObject.clientParam.data.attributes.location_address.latitude,
      );
      setLocationLongitude(
        contactInformationObject.clientParam.data.attributes.location_address.longitude,
      );
    }
  }, [contactInformationObject]);

  const Body = () => {
    const [addContactForm, { data, loading, error }] = useMutation(ADD_CONTACT_FORM_MUTATION);

    const validationSchema = yup.object({
      full_name: yup.string().required(t('validationsForm.required')),
      subject: yup.string().required(t('validationsForm.required')),
      email: yup
        .string()
        .email(t('validationsForm.validEmail'))
        .required(t('validationsForm.required')),
      message: yup.string().required(t('validationsForm.required')),
    });

    const formik = useFormik({
      initialValues: {
        full_name: '',
        subject: '',
        email: '',
        message: '',
      },
      validationSchema,
      onSubmit: async (values) => {
        await addContactForm({
          variables: { ...values },
        });
      },
    });

    useEffect(() => {
      if (!window.location.hash) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }, []);

    useEffect(() => {
      if (!loading && !error && data) {
        handleOpenSuccess();
        formik.resetForm();
      }
    }, [data]);

    return (
      <div className={classes.contactWrapper}>
        <div className={classes.contactContentWrapper}>
          <div className={classes.headerForm}>
            <h2 className={fontClasses.h2}>{t('contactPage.headerText')}</h2>
            <p className={clsx(fontClasses.p, classes.contactFormHeaderText)}>
              {t('contactPage.headerInfomationText')}
            </p>
          </div>
          <div style={{ width: '100%' }}>
            <Grid container rowSpacing={{ xs: 4, md: 4 }} spacing={5}>
              <Grid item sm={12} xs={12} md={12} lg={7}>
                <form onSubmit={formik.handleSubmit}>
                  {Object.keys(formik.errors).length > 0 && formik.submitCount > 0 && (
                    <div className={classes.alertError}>
                      <Alert severity='error'>{t('validationsForm.complete')}</Alert>
                    </div>
                  )}
                  {error && (
                    <div className={classes.alertError}>
                      <Alert severity='error'>{t('validationsForm.serverError')}</Alert>
                    </div>
                  )}
                  <div className={classes.inputTextBox}>
                    <p className={clsx(fontClasses.p, classes.titleInput)}>
                      {t('contactPage.name')}
                    </p>
                    <TextField
                      fullWidth
                      variant='outlined'
                      id='full_name'
                      name='full_name'
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={classes.inputTextBox}>
                    <p className={clsx(fontClasses.p, classes.titleInput)}>
                      {t('contactPage.subject')}
                    </p>
                    <TextField
                      fullWidth
                      variant='outlined'
                      id='subject'
                      name='subject'
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={classes.inputTextBox}>
                    <p className={clsx(fontClasses.p, classes.titleInput)}>
                      {t('contactPage.email')}
                    </p>
                    <TextField
                      fullWidth
                      variant='outlined'
                      id='email'
                      name='email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={classes.inputTextBox}>
                    <p className={clsx(fontClasses.p, classes.titleInput)}>
                      {t('contactPage.message')}
                    </p>
                    <TextField
                      fullWidth
                      variant='outlined'
                      multiline
                      rows={4}
                      id='message'
                      name='message'
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      InputProps={{
                        style: {
                          padding: '15px',
                        },
                      }}
                    />
                  </div>
                  <div className={classes.buttonBox}>
                    <p className={clsx(fontClasses.smallText, classes.termsText)}>
                      {t('contactPage.conditions')}
                    </p>
                    <CustomPrimaryButton
                      label={t('contactPage.submitButton')}
                      icon={<ArrowForwardIcon />}
                      submit
                    />
                  </div>
                </form>
              </Grid>
              <Grid item sm={12} xs={12} md={12} lg={5}>
                <div className={classes.contactInfomationBox}>
                  <img
                    src={URL_IMAGES.URL_BUTTERFLY}
                    alt='Butterfly Image'
                    className={classes.butterflyImage}
                  />
                  <h5 className={clsx(fontClasses.h5, classes.titleBox)}>
                    {t('contactPage.titleBoxText')}
                  </h5>
                  <div className={classes.infoBoxSpace}>
                    <p className={clsx(fontClasses.p, classes.titleInfoBoxSpace)}>
                      {t('contactPage.phoneLabel')}
                    </p>
                    <p className={fontClasses.p}>{t('contactPage.phoneText')}</p>
                  </div>
                  <div className={classes.infoBoxSpace}>
                    <p className={clsx(fontClasses.p, classes.titleInfoBoxSpace)}>
                      {t('contactPage.emailLabel')}
                    </p>
                    <p className={fontClasses.p}>
                      {contactInformationObject
                        ? contactInformationObject.clientParam.data.attributes.email
                        : null}
                    </p>
                  </div>
                  <div className={classes.infoBoxSpace}>
                    <p className={clsx(fontClasses.p, classes.titleInfoBoxSpace)}>
                      {t('contactPage.addressLabel')}
                    </p>
                    <p className={fontClasses.p}>{t('contactPage.addressText')}</p>
                  </div>
                  <div className={classes.mapStyling}>
                    <Map
                      mapboxAccessToken={mapboxKey}
                      initialViewState={{
                        longitude: locationLongitude,
                        latitude: locationLatitude,
                        zoom: 14,
                      }}
                      style={{ width: '100%', height: '200px', borderRadius: '10px' }}
                      mapStyle='mapbox://styles/jeanvegad/clnfkgnl1083001qx6dbdg9r7'
                      attributionControl={false}
                      logoPosition='bottom-right'
                    >
                      <Marker
                        longitude={locationLongitude}
                        latitude={locationLatitude}
                        anchor='bottom'
                      >
                        <PlaceIcon style={{ fontSize: '40px', color: COLORS.green }} />
                      </Marker>
                    </Map>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Metatags
        title='Caribbean Adventures | Contact'
        description='Any questions or recommendations? Just fill out the form on our page and send us a message.'
      />
      <Body />
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSuccess} severity='success' sx={{ width: '100%' }}>
          <AlertTitle> {t('contactPage.succesTitle')}</AlertTitle>
          {t('contactPage.succesMessage')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactPage;
