import { gql } from '@apollo/client';

export const GET_TOUR_BY_ID = gql`
  query ($tourId: ID) {
    tour(id: $tourId) {
      data {
        id
        attributes {
          category {
            data {
              attributes {
                name
              }
            }
          }
          overview
          included
          duration
          images {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          is_ticket
          itineray
          languages
          min_capacity
          name
          price
          tag
          location
        }
      }
    }
  }
`;

export const GET_FILTERED_TOURS = gql`
  query Query($filters: TourFiltersInput, $sortOrder: [String]) {
    tours(filters: $filters, sort: $sortOrder) {
      data {
        id
        attributes {
          category {
            data {
              attributes {
                name
              }
            }
          }
          overview
          duration
          images {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          languages
          min_capacity
          name
          price
          tag
        }
      }
    }
  }
`;

export const SIMILAR_TOURS = gql`
  query Tours($page: Int!, $filters: TourFiltersInput) {
    tours(pagination: { page: $page, pageSize: 4 }, filters: $filters) {
      data {
        id
        attributes {
          name
          images {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          price
        }
      }
    }
  }
`;

export const FEATURED_TOUR_QUERY = gql`
  query Tours($page: Int!) {
    tours(pagination: { page: $page, pageSize: 4 }) {
      data {
        id
        attributes {
          name
          images {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          price
        }
      }
    }
  }
`;
