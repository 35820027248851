/* eslint-disable camelcase */
import { useQuery } from '@apollo/client';
import { WarningAmberOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  Skeleton,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Metatags from '../../components/MetaTags/metatags';
import { COLORS, URL_IMAGES } from '../../constants';
import { GET_ABOUT_US_CLIENT_DATA } from '../../queries/clientParams';
import GET_CRUISES_BY_DATE from '../../queries/cruises';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const AboutPage = () => {
  const classes = styles();
  const { t } = useTranslation();
  const fontClasses = fontsStyles();

  const {
    data: clientData,
    loading: isClientDataLoading,
    error: isClientDataError,
  } = useQuery(GET_ABOUT_US_CLIENT_DATA);

  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  const Header = () => {
    return (
      <>
        <div className={classes.headerWrapper}></div>
        <div className={classes.headerContentWrapper}>
          <h2 className={clsx(fontClasses.h2, classes.titleText)}>{t('aboutPage.smallTeam')}</h2>
          {isClientDataLoading || isClientDataError ? (
            <>
              {[...Array(4)].map((__, index) => (
                <>
                  <Skeleton
                    key={`${index}-about-desc-skel`}
                    variant='rectangular'
                    className={classes.aboutDescriptionSkeletonLong}
                  />
                </>
              ))}
            </>
          ) : (
            <p className={clsx(fontClasses.p, fontClasses.justify)}>
              {clientData?.clientParam?.data?.attributes?.about_us || ''}
            </p>
          )}
        </div>
      </>
    );
  };

  const CruiseSeason = () => {
    const [dateValue, setDateValue] = React.useState(dayjs());
    const {
      data: cruiseDatesData,
      loading: areDatesLoading,
      error: areDatesError,
    } = useQuery(GET_CRUISES_BY_DATE, {
      variables: {
        filters: {
          date: {
            between: [
              dateValue.startOf('month').format('YYYY-MM-DD'),
              dateValue.endOf('month').format('YYYY-MM-DD'),
            ],
          },
        },
      },
    });

    const highlightedDays = useMemo(
      () =>
        (cruiseDatesData?.cruiseDates?.data || []).map((cruiseDate) => {
          return dayjs(cruiseDate.attributes.date).get('date');
        }),
      [cruiseDatesData],
    );

    const currentDayCruises = useMemo(() => {
      const filteredCruiseDates = (cruiseDatesData?.cruiseDates?.data || []).filter(
        (cruiseDate) => cruiseDate.attributes.date === dateValue.format('YYYY-MM-DD'),
      );
      return filteredCruiseDates.map((cruiseDate) => {
        const { eta, etd, cruise_id } = cruiseDate.attributes;
        return {
          name: cruise_id?.data?.attributes?.name || '',
          eta: eta.slice(0, -7),
          etd: etd.slice(0, -7),
        };
      });
    }, [cruiseDatesData, dateValue]);

    const monthChangeHandler = (newDate) => {
      setDateValue(newDate);
    };

    function ServerDay(props) {
      const { day, outsideCurrentMonth, ...other } = props;

      const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

      return (
        <Badge
          key={props.day.toString()}
          overlap='circular'
          color='primary'
          className={classes.badgeButton}
          variant='dot'
          invisible={!isSelected}
        >
          <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
      );
    }

    return (
      <div id={'cruise-season'} className={classes.cruiseWrapper}>
        <div className={classes.cruiseContentWrapper}>
          <h2 className={fontClasses.h2}>{t('aboutPage.cruiseSeason')}</h2>
          <p className={clsx(fontClasses.p, classes.seasonCruiseText)}>
            {t('aboutPage.cruiseSeasonText')}
          </p>
          <div className={classes.calendarBox}>
            <Grid container spacing={6}>
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <DateCalendar
                  sx={{ maxHeight: 'unset', width: '100%' }}
                  className={classes.datePicker}
                  slots={{
                    day: ServerDay,
                  }}
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={6}
                  value={dateValue}
                  onChange={(newValue) => setDateValue(newValue)}
                  onMonthChange={monthChangeHandler}
                />
              </Grid>
              <Grid item lg={5} md={4} sm={12} xs={12}>
                {(currentDayCruises.length > 0 || areDatesLoading) &&
                  (areDatesLoading ? (
                    <div>
                      <Skeleton variant='text' className={classes.cruisesSkeletonTitle} />
                      <div className={classes.cruisesDatesBox}>
                        {[...Array(3)].map((__, index) => (
                          <div key={`${index}-about-cruises-skel`} className={classes.cruisesBox}>
                            <Skeleton
                              variant='rectangular'
                              className={classes.cruisesSkeletonIcon}
                            />
                            <div>
                              <Skeleton
                                variant='text'
                                className={classes.cruisesSkeletonSubTitle}
                              />
                              <Skeleton variant='text' className={classes.cruisesSkeletonTime} />
                              <Skeleton variant='text' className={classes.cruisesSkeletonTime} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className={fontClasses.p}>{t('aboutPage.availableCruises')}</p>
                      <div className={classes.cruisesDatesBox}>
                        {currentDayCruises.map((item, index) => (
                          <div
                            key={`${index}-about-cruises-available`}
                            className={classes.cruisesBox}
                          >
                            <DirectionsBoatIcon
                              sx={{ fontSize: '30px' }}
                              className={classes.icon}
                            />
                            <div>
                              <p
                                className={clsx(
                                  fontClasses.p,
                                  fontClasses.semiBold,
                                  classes.cruiseTitle,
                                )}
                              >
                                {item.name}
                              </p>
                              <p className={clsx(fontClasses.p)} style={{ fontSize: '14px' }}>
                                <span className={fontClasses.semiBold}>{t('aboutPage.eta')}: </span>
                                {item.eta}
                              </p>
                              <p className={fontClasses.p} style={{ fontSize: '14px' }}>
                                <span className={fontClasses.semiBold}>{t('aboutPage.etd')}: </span>
                                {item.etd}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                {(currentDayCruises.length < 1 || areDatesError) && (
                  <div className={classes.emptyCruiseBox}>
                    <div className={classes.emptyContentCruiseBox}>
                      {areDatesError ? (
                        <>
                          <WarningAmberOutlined
                            sx={{ fontSize: '50px' }}
                            className={classes.icon}
                          />
                          <p
                            className={clsx(
                              fontClasses.p,
                              fontClasses.center,
                              classes.noCruisesText,
                            )}
                          >
                            {t('aboutPage.availableCruisesError')}
                          </p>
                        </>
                      ) : (
                        <>
                          <InfoOutlinedIcon sx={{ fontSize: '50px' }} className={classes.icon} />
                          <p
                            className={clsx(
                              fontClasses.p,
                              fontClasses.center,
                              classes.noCruisesText,
                            )}
                          >
                            {t('aboutPage.noAvailableCruises')}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
            <img src={URL_IMAGES.URL_FROG} alt='Frog image' className={classes.frogImage} />
          </div>
        </div>
      </div>
    );
  };

  const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <div className={classes.faqWrapper} id={'frequently-asked'}>
        <div className={classes.faqContentWrapper}>
          <h2 className={fontClasses.h2}>{t('aboutPage.faq')}</h2>
          {isClientDataLoading || isClientDataError ? (
            <div>
              {[...Array(4)].map((__, index) => (
                <Accordion
                  key={`${index}-about-faq-skel`}
                  expanded={false}
                  disableGutters
                  elevation={0}
                >
                  <AccordionSummary expandIcon={<AddIcon style={{ color: COLORS.black }} />}>
                    <Skeleton variant='text' className={classes.faqSkeleton} />
                  </AccordionSummary>
                </Accordion>
              ))}
            </div>
          ) : (
            <div>
              {(clientData?.clientParam?.data?.attributes?.frequently_asked || []).map(
                (item, index) => (
                  <Accordion
                    expanded={expanded === `faqPanel${index}`}
                    disableGutters
                    elevation={0}
                    onChange={handleChangeAccordion(`faqPanel${index}`)}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `faqPanel${index}` ? (
                          <RemoveIcon style={{ color: COLORS.orange }} />
                        ) : (
                          <AddIcon style={{ color: COLORS.black }} />
                        )
                      }
                      aria-controls={`faqPanel${index}-content`}
                      id={`faqPanel${index}-header`}
                    >
                      <p
                        className={clsx(fontClasses.p, classes.questionTitle)}
                        style={{
                          color: expanded === `faqPanel${index}` ? COLORS.orange : COLORS.black,
                        }}
                      >
                        {item.question}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className={fontClasses.p}>{item.answer}</p>
                    </AccordionDetails>
                  </Accordion>
                ),
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Metatags
        title='Caribbean Adventures | About Us'
        description='Your premier choice for unforgettable adventures in the Caribbean side of Costa Rica!'
      />
      <Header />
      <CruiseSeason />
      <FAQ />
    </>
  );
};

export default AboutPage;
