export function formatTours(response) {
  return {
    name: response.tour.data.attributes.name,
    price: response.tour.data.attributes.price,
    images: response.tour.data.attributes.images.data.map((item) => {
      return {
        url: item.attributes.url,
        alt: item.attributes.alternativeText,
      };
    }),
    overview: response.tour.data.attributes.overview,
    included: response.tour.data.attributes.included,
    duration: response.tour.data.attributes.duration,
    minCapacity: response.tour.data.attributes.min_capacity,
    languagues: response.tour.data.attributes.languages,
    isTicket: response.tour.data.attributes.is_ticket,
    itineray: response.tour.data.attributes.itineray,
    location: response.tour.data.attributes.location,
  };
}

export function formatFeaturedTours(response) {
  return response.tours.data.map((item) => ({
    id: item.id,
    name: item.attributes.name,
    price: item.attributes.price,
    img: item.attributes.images.data[0]?.attributes.url || '',
    alt: item.attributes.images.data[0]?.attributes.alternativeText,
  }));
}
