import { gql } from '@apollo/client';

export const GET_CANCELATION_POLICY = gql`
  query Query {
    clientParam {
      data {
        attributes {
          cancelation_policy
        }
      }
    }
  }
`;

export const GET_ABOUT_US_CLIENT_DATA = gql`
  query Query {
    clientParam {
      data {
        attributes {
          about_us
          frequently_asked
        }
      }
    }
  }
`;

export const GET_POLICIES_FILES = gql`
  query Query {
    clientParam {
      data {
        attributes {
          terms_of_service {
            data {
              attributes {
                url
                name
                mime
              }
            }
          }
          reimbursements {
            data {
              attributes {
                url
                name
                mime
              }
            }
          }
          privacy_policy {
            data {
              attributes {
                url
                name
                mime
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_INFORMATION_DATA = gql`
  query Query {
    clientParam {
      data {
        attributes {
          phone
          location_address
          email
        }
      }
    }
  }
`;
