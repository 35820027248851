export function formatReview(response) {
  return response.reviews.data.map((item) => ({
    review_date: item.attributes.review_date,
    text: item.attributes.text,
    title: item.attributes.title,
    username: item.attributes.username,
    user_avatar: item.attributes.user_avatar,
    rating: item.attributes.rating,
    url: item.attributes.url,
  }));
}
