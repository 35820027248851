export const home = '/';
export const about = '/about';
export const tourCatalogue = '/tours';
export const tour = '/tours';
export const tourConfirmation = '/booking';
export const tourConfirmationSolution = '/booking-confirmation';
export const province = '/our-province';
export const contact = '/contact';
export const writeReview =
  'https://www.tripadvisor.com/UserReviewEdit-g309264-d8842878-Caribbean_Adventures_Costa_Rica-Puerto_Limon_Province_of_Limon.html';
export const notFound = '/*';
export const docs = '/docs';
