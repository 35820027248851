import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  buttonStructure: {
    textDecoration: 'none',
    padding: '10px 20px',
    height: '45px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
    [themeBreakpoints.down('sm')]: {
      gap: 10,
      padding: '10px 15px',
    },
  },
  mainStyle: {
    color: COLORS.white,
    backgroundColor: COLORS.green,
    borderColor: COLORS.green,
  },
  mainStyleDisabled: {
    backgroundColor: '#e0e0e0',
    borderColor: '#e0e0e0',
    cursor: 'not-allowed',
  },
  secondaryStyle: {
    color: COLORS.green,
    backgroundColor: COLORS.white,
    borderColor: COLORS.green,
  },
  secondaryStyleDisabled: {
    borderColor: '#e0e0e0',
    backgroundColor: COLORS.white,
    cursor: 'not-allowed',
  },
  tertiaryStyle: {
    color: COLORS.white,
    backgroundColor: COLORS.orange,
    borderColor: COLORS.orange,
  },
  tertiaryStyleDisabled: {
    backgroundColor: '#e0e0e0',
    borderColor: '#e0e0e0',
    cursor: 'not-allowed',
  },
  iconButtonBox: {
    color: COLORS.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButtonBoxDisabled: {
    color: COLORS.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: 'inherit',
  },
  buttonTextDisabled: {
    color: COLORS.black,
  },
  secondaryButtonText: {
    color: COLORS.green,
  },
}));

export default useStyles;
