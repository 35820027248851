export const black = '#424242';
export const white = '#FFFFFF';
export const green = '#65b741';
export const orange = '#FF6C22';
export const lightYellow = '#FAFBE7';
export const lightGreen = '#ECF6E7';
export const red = '#EF5350';
export const lightGray = '#F2F3F7';
export const stroke = '#D5D4DF';

// revisar
export const gray = '#424242';
export const cyan = '#4FBCDC';
export const ligthCyan = '#EEFBFF';
export const yellow = '#FFD93E';
export const blue = '#0077FF';
export const lightLime = '#ECF6E7';
export const lime = '#E4E77B';
export const whatsappGreen = '#25d366';
